import { ActionType } from 'typesafe-actions';

import * as PaymentDataActions from 'store/actions/paymentdata';
import { call, debounce, put } from 'redux-saga/effects';
import { PaymentDataResponse } from 'store/types/paymentdata';
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions';
import Endpoints from 'endpoints';
import GipsyyGDSApi from 'apis/GipsyGDS';

function* getPaymentData(action: ActionType<typeof PaymentDataActions.getPaymentData.request>) {
  const { paymentID } = action.payload;

  try {
    const response: { data: PaymentDataResponse } = yield GipsyyGDSApi.get(Endpoints.getPaymentData(paymentID));
    yield put(PaymentDataActions.getPaymentData.success(response.data));
    yield call(resolvePromiseAction, action, response.data);
  } catch (error) {
    yield put(PaymentDataActions.getPaymentData.failure());
    yield call(rejectPromiseAction, action, error);
  }
}

export const watchPaymentData = [debounce(1000, PaymentDataActions.getPaymentData.request, getPaymentData)];
