import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from 'store/actions/static_pages';
import StaticPage from 'models/StaticPage';

export interface IStaticPageStore {
  legal: StaticPage[];
  usefulLinks: StaticPage[];
  sidebar: StaticPage[];
  help: StaticPage[];
  all: StaticPage[];
}

const initialState = {
  legal: [],
  usefulLinks: [],
  sidebar: [],
  help: [],
  all: [],
};

export const staticPageReducer = createReducer<IStaticPageStore, ActionType<typeof actions>>(initialState).handleAction(
  actions.getStaticPages.success,
  (state, { payload }) => {
    const legal: StaticPage[] = [];
    const usefulLinks: StaticPage[] = [];
    const help: StaticPage[] = [];
    const sidebar: StaticPage[] = [];

    payload.forEach((page) => {
      if (page.legal) legal.push(page);
      if (page.usefulLinks) usefulLinks.push(page);
      if (page.sidebar) sidebar.push(page);
      if (page.help) help.push(page);
    });

    return { legal, usefulLinks, sidebar, help, all: payload };
  },
);
