import { ActionType } from 'typesafe-actions';
import { call, put, takeLeading } from 'redux-saga/effects';
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions';

import * as actions from 'store/actions/newsletter';
import SendGridApi from 'apis/SendGrid';
import Endpoints from 'endpoints';

function* addNewsletterSubscriber(action: ActionType<typeof actions.addNewsletterSubscriber.request>) {
  try {
    const { contacts, list_ids: listIds } = action.payload;

    yield SendGridApi.put(Endpoints.contact(), {
      list_ids: listIds,
      contacts,
    });

    yield call(resolvePromiseAction, action);
    yield put(actions.addNewsletterSubscriber.success());
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.addNewsletterSubscriber.failure());
  }
}

export const watchNewsletter = [takeLeading(actions.addNewsletterSubscriber.request, addNewsletterSubscriber)];
