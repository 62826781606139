const StaticPages = {
  staticPagesPath: () => {
    return 'api/static_pages';
  },
  staticPagePath: (slug: string) => {
    return `api/static_page/${slug}`;
  },
};

export default StaticPages;
