import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from 'store/actions/topDestinations';

import { TopDestination } from 'store/types/topDestinations';

const initialState: Array<TopDestination> = [];

export const topDestinationsReducer = createReducer<Array<TopDestination>, ActionType<typeof actions>>(
  initialState,
).handleAction(actions.getTopDestinations.success, (state, { payload }) => ({
  ...state,
  ...payload,
}));
