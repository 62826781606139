import { createReducer, ActionType } from 'typesafe-actions';

import * as paymentActions from 'store/actions/payments';
import * as tripActions from 'store/actions/trips';
import { PaymentsState } from 'store/types/payments';

const actions = { ...tripActions, ...paymentActions };

const initialState: PaymentsState = {
  payment: null,
  paymentMethods: [],
  tripPayment: null,
};

export const paymentsReducer = createReducer<PaymentsState, ActionType<typeof actions>>(initialState)
  .handleAction(actions.getPaymentMethods.success, (state, { payload }) => ({
    ...state,
    paymentMethods: payload,
  }))
  .handleAction([actions.getPayment.success, actions.makePayment.success], (state, { payload }) => ({
    ...state,
    payment: payload,
  }))
  .handleAction(actions.setTripPayment, (state, { payload }) => ({
    ...state,
    tripPayment: payload.payment,
  }))
  .handleAction(actions.setPayment, (state, { payload }) => ({
    ...state,
    payment: payload.payment,
  }))
  .handleAction(actions.closeTrip.success, (state, { payload }) => {
    if (state.payment) {
      return {
        ...state,
        payment: {
          ...state.payment,
          price: payload.payment.price,
          trips: state.payment.trips
            .filter((trip) => trip.id !== payload.id)
            .map((trip) => ({ ...trip, return: false })),
        },
      };
    }

    return state;
  })
  .handleAction(actions.deleteTrip.success, (state, { payload }) => {
    if (state.payment) {
      return {
        ...state,
        payment: {
          ...state.payment,
          price: payload.payment.price,
          trips: state.payment.trips.filter((trip) => trip.id !== payload.id),
        },
      };
    }

    return state;
  })
  .handleAction(actions.cleanTrips, (state) => ({
    ...state,
    payment: null,
    tripPayment: null,
  }));
