export const numberFormatter = (value: number | string | undefined | null): string => {
  let finalValue;
  switch (typeof value) {
    case 'string':
      finalValue = Number(value);
      break;
    case 'number':
      finalValue = value;
      break;
    default:
      finalValue = 0.0;
      break;
  }
  const [currency, decimal] = finalValue.toFixed(2).split('.');
  return `${currency.replace(/\B(?=(\d{3})+(?!\d))/g, '.')},${decimal}`;
};
