import axios from 'axios';
import humps from 'humps';
import Qs from 'qs';

const GipsyApi = axios.create({
  baseURL: process.env.REACT_APP_GIPSY_URL,
  params: { locale: process.env.REACT_APP_I18N_DEFAULT_LANGUAGE, format: 'json' },
  transformResponse: [
    ...(axios.defaults.transformResponse as []),
    (data) => {
      if (data instanceof Blob) return data;

      return humps.camelizeKeys(data);
    },
  ],
  paramsSerializer(params) {
    return Qs.stringify(humps.decamelizeKeys(params, { split: /(?=[A-Z0-9])/ }), {
      arrayFormat: 'brackets',
    });
  },
});

export default GipsyApi;
