import axios from 'axios';
import humps from 'humps';
import Qs from 'qs';

const SendGridApi = axios.create({
  baseURL: process.env.REACT_APP_SENDGRID_URL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_SENDGRID_API_KEY}`,
    'Content-Type': 'application/json',
  },
  transformResponse: [...(axios.defaults.transformResponse as []), (data) => humps.camelizeKeys(data)],
  paramsSerializer(params) {
    return Qs.stringify(humps.decamelizeKeys(params), {
      arrayFormat: 'brackets',
    });
  },
});

export default SendGridApi;
