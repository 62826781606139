import React from 'react';

// import animatedLoader from 'images/logo-animation.gif';
import animatedLoader from 'images/logo-animation.mp4';

import styles from './index.module.css';

const FullPageLoader: React.VFC = () => (
  <div className={styles.container}>
    <video autoPlay muted>
      <source src={animatedLoader} />
    </video>
    {/* <img src={animatedLoader} alt="Animated loader" /> */}
  </div>
);

export default FullPageLoader;
