import { combineReducers } from 'redux';
import { authReducer } from './auth';
import { faqReducer } from './faqs';
import { locationReducer } from './locations';
import { staticPageReducer } from './static_pages';
import { paymentsReducer } from './payments';
import { usersReducer } from './users';
import { filtersReducer } from './filters';
import { searchReducer } from './search';
import { tripsReducer } from './trips';
import { uiReducer } from './ui';
import { subscriptionReducer } from './subscription';
import { topDestinationsReducer } from './topDestinations';
import { PaymentDataReducer } from './paymentdata';
import { CalendarReducer } from './calendar';
import { bannersReducer } from './banners';
import { RoutesReducer } from './routes';

const rootReducer = combineReducers({
  auth: authReducer,
  banners: bannersReducer,
  calendar: CalendarReducer,
  faqs: faqReducer,
  filters: filtersReducer,
  location: locationReducer,
  payments: paymentsReducer,
  routes: RoutesReducer,
  search: searchReducer,
  staticPages: staticPageReducer,
  subscription: subscriptionReducer,
  users: usersReducer,
  ui: uiReducer,
  topDestinations: topDestinationsReducer,
  trips: tripsReducer,
  paymentdata: PaymentDataReducer,
});

export type IStore = ReturnType<typeof rootReducer>;

export default rootReducer;
