import React from 'react';
import cx from 'classnames';

import styles from './index.module.css';

type SpinnerProps = {
  isLoading?: boolean;
};

const Spinner: React.VFC<SpinnerProps> = ({ isLoading }) => (
  <div className={cx({ [styles.container]: isLoading, [styles.notVisible]: !isLoading })}>
    <div className={cx('spinner-border', styles.spinner)} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

export default Spinner;
