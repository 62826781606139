import { ActionType } from 'typesafe-actions';
import { call, put, select, take, takeLeading } from 'redux-saga/effects';
import { resolvePromiseAction, rejectPromiseAction } from 'redux-saga-promise-actions';

import GipsyApi from 'apis/Gipsy';
import Endpoints from 'endpoints';
import * as actions from 'store/actions';
import { IStore } from 'store/reducers';
import { TLoginSucceededPayload } from '../types/auth';

import { request } from './api';

function* signIn(action: ActionType<typeof actions.loginRequest.request>) {
  try {
    const {
      payload: { email, password },
    } = action;
    const response: { data: TLoginSucceededPayload } = yield call(request, {
      method: 'post',
      url: Endpoints.loginPath(),
      params: { user: { email, password } },
    });
    localStorage.setItem(
      'authData',
      JSON.stringify({
        token: response.data.token,
        refreshToken: response.data.refreshToken,
        sessionExpiration: response.data.refreshTokenExpiryDate,
      }),
    );
    yield call(resolvePromiseAction, action, response.data);
    yield put(actions.loginRequest.success(response.data));
    yield put(actions.getUser.request());
    yield take(actions.getUser.success);
  } catch (error) {
    yield call(rejectPromiseAction, action, error);
    yield put(actions.loginRequest.failure(error));
  }
}

function* mobileSignIn(action: ActionType<typeof actions.mobileLoginRequest.request>) {
  try {
    const response = yield call(request, {
      method: 'post',
      url: Endpoints.loginPath(),
      params: {
        user: {
          phone: action.payload.phone,
          ...(action.payload.confirmationCode ? { confirmationCode: action.payload.confirmationCode } : {}),
        },
      },
    });

    if (action.payload.confirmationCode) {
      localStorage.setItem(
        'authData',
        JSON.stringify({ token: response.data.token, refreshToken: response.data.refreshToken }),
      );

      yield call(resolvePromiseAction, action, response.data);
      yield put(actions.mobileLoginRequest.success(response.data));
      yield put(actions.getUser.request());
      yield take(actions.getUser.success);
    } else {
      yield call(resolvePromiseAction, action, response.data);
    }
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.mobileLoginRequest.failure(err));
  }
}

function* signUp(action: ActionType<typeof actions.signUp.request>) {
  try {
    const { payload } = action;

    yield GipsyApi.request({
      method: 'post',
      url: Endpoints.signUpPath(),
      params: {
        user: payload,
      },
    });

    yield call(resolvePromiseAction, action);
    yield put(actions.signUp.success());
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.signUp.failure(err));
  }
}

function* validateVerificationCode(
  action:
    | ActionType<typeof actions.validateVerificationCode.request>
    | ActionType<typeof actions.resendVerificationCode.request>,
) {
  try {
    const {
      payload: { phone, email, confirmationCode },
    } = action;

    yield GipsyApi.request({
      method: action.type === 'VALIDATE_VERIFICATION_CODE' ? 'GET' : 'POST',
      url: Endpoints.confirmationPath(),
      params: {
        phone,
        email: email?.toLowerCase().trim(),
        confirmationCode,
      },
    });

    yield call(resolvePromiseAction, action);
    yield put(actions.validateVerificationCode.success());
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.validateVerificationCode.failure(err));
  }
}

function* remindPassword(
  action: ActionType<typeof actions.remindPassword.request> | ActionType<typeof actions.confirmRemindPassword.request>,
) {
  try {
    yield call(request, {
      method: action.type === 'REMIND_PASSWORD' ? 'POST' : 'PATCH',
      url: Endpoints.passwordPath(),
      params: { user: action.payload },
    });

    yield call(resolvePromiseAction, action);
    yield put(actions.remindPassword.success());
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.remindPassword.failure(err));
  }
}

function* refreshToken() {
  const refToken = yield select((state: IStore) => state.auth.refreshToken);

  if (!refToken) {
    yield put(actions.logout.request());
    yield take(actions.logout.success);
    yield put(actions.refreshToken.failure());
    yield put(actions.setLoading(false));
    return;
  }

  try {
    const response = yield call(request, {
      method: 'patch',
      url: Endpoints.loginPath(),
      params: {
        user: { refreshToken: refToken },
      },
    });

    localStorage.setItem('authData', JSON.stringify(response.data));

    yield put(actions.refreshToken.success(response.data));
  } catch (err) {
    yield put(actions.logout.request());
    yield take(actions.logout.success);
    yield put(actions.setLoading(false));
    yield put(actions.refreshToken.failure());
  }
}

function* signOut(action: ActionType<typeof actions.logout.request>) {
  const token = yield select((state: IStore) => state.auth.token);

  if (!token) {
    return;
  }

  try {
    yield call(request, {
      method: 'DELETE',
      url: Endpoints.loginPath(),
      validateStatus: () => true,
    });

    localStorage.removeItem('authData');
    localStorage.removeItem('userData');

    yield call(resolvePromiseAction, action);
    yield put(actions.logout.success());
    yield put(actions.setLoading(false));
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.logout.failure());
    yield put(actions.setLoading(false));
  }
}

export const watchAuth = [
  takeLeading(actions.signUp.request, signUp),
  takeLeading(actions.logout.request, signOut),
  takeLeading(actions.loginRequest.request, signIn),
  takeLeading(actions.refreshToken.request, refreshToken),
  takeLeading(actions.mobileLoginRequest.request, mobileSignIn),
  takeLeading([actions.remindPassword.request, actions.confirmRemindPassword.request], remindPassword),
  takeLeading(
    [actions.validateVerificationCode.request, actions.resendVerificationCode.request],
    validateVerificationCode,
  ),
];
