import { all, call, put, select, take, takeLeading, debounce } from 'redux-saga/effects';
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions';
import { request } from 'store/sagas/api';

import { ActionType } from 'typesafe-actions';

import * as paymentActions from 'store/actions/payments';
import * as tripsActions from 'store/actions/trips';
import { IStore } from 'store/reducers';
import { FreeTickets, FreeTicketsPayload, VerifyTripPriceResponse } from 'store/types/trips';
import Endpoints from 'endpoints';
import { GetPaymentResponse } from 'store/types/payments';
import GipsyyGDSApi from 'apis/GipsyGDS';

const actions = { ...paymentActions, ...tripsActions };

function* getTrips(action: ActionType<typeof actions.getTrips.request>) {
  const { arrivalLocation, departureDate, departureLocation } = action.payload;

  try {
    const response = yield call(request, {
      method: 'get',
      url: Endpoints.tripsPath(),
      params: {
        arrivalLocation,
        departureLocation,
        departureDate,
      },
    });

    yield call(resolvePromiseAction, action, response.data ?? []);
    yield put(actions.getTrips.success(response.data ?? []));
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.getTrips.failure());
  }
}

function* getTrip(action: ActionType<typeof actions.getTrip.request>) {
  try {
    const {
      payload: { id, userId, connectionIndex },
    } = action;

    const response = yield call(request, {
      method: 'get',
      url: Endpoints.tripPath(id),
      params: {
        userId,
        connectionIndex,
      },
    });

    yield call(resolvePromiseAction, action, response.data);
    yield put(actions.getTrip.success(response.data));
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.getTrip.failure());
  }
}

function* getTickets(action: ActionType<typeof actions.getTickets.request>) {
  try {
    const { bookingId, arrivalLocationId, departureAtLocal, departureLocationId, email } = action.payload;

    const response = yield call(request, {
      method: 'get',
      url: Endpoints.ticketsPath(),
      params: {
        bookingId,
        userEmail: email,
        departureLocationId,
        arrivalLocationId,
        departureAtLocal,
      },
    });

    yield call(resolvePromiseAction, action, response.data);
    yield put(actions.getTickets.success(response.data));
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.getTickets.failure());
  }
}

function* saveTickets(action: ActionType<typeof actions.saveTickets.request>) {
  try {
    const { arrivalLocationId, bookingId, departureAtLocal, departureLocationId, email, ids } = action.payload;

    const response = yield call(request, {
      method: 'get',
      url: Endpoints.ticketsPath(),
      responseType: 'blob',
      params: {
        format: 'pdf',
        ids,
        bookingId,
        departureAtLocal,
        userEmail: email,
        arrivalLocationId,
        departureLocationId,
      },
    });

    const blob = new Blob([response.data], { type: 'application/pdf' });

    const fileURL = URL.createObjectURL(blob);

    yield call(resolvePromiseAction, action, { fileURL });
    yield put(actions.saveTickets.success({ fileURL }));
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.saveTickets.failure());
  }
}

function* createTrip(action: ActionType<typeof actions.createTrip.request>) {
  try {
    const { payload } = action;

    const response = yield call(request, {
      method: 'post',
      url: Endpoints.tripsPath(),
      params: payload,
    });

    yield call(resolvePromiseAction, action, response.data);
    yield put(actions.createTrip.success(response.data));
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.createTrip.failure());
  }
}

function* deleteTrip(action: ActionType<typeof actions.deleteTrip.request>) {
  try {
    const { id } = action.payload;

    const response = yield call(request, {
      method: 'delete',
      url: Endpoints.tripPath(id),
    });

    yield call(resolvePromiseAction, action, response.data);
    yield put(actions.deleteTrip.success(response.data));
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.deleteTrip.failure());
  }
}

function* closeTrip(action: ActionType<typeof actions.closeTrip.request>) {
  try {
    const { id } = action.payload;

    const response = yield call(request, {
      method: 'delete',
      url: Endpoints.tripPath(id),
    });

    yield call(resolvePromiseAction, action, response.data);
    yield put(actions.closeTrip.success(response.data));
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.closeTrip.failure());
  }
}

function* makeReservation(action: ActionType<typeof actions.makeReservation.request>) {
  try {
    const { id, ticketsAttributes, userId } = action.payload;

    const tickets = ticketsAttributes.map(({ id: ticketId, seatIdentifier, price }) => ({
      id: ticketId,
      seatIdentifier,
      price,
    }));

    const response = yield call(request, {
      method: 'patch',
      url: Endpoints.tripPath(id),
      params: {
        ticketsAttributes: tickets,
        userId,
      },
    });

    yield call(resolvePromiseAction, action, response.data);
    yield put(actions.makeReservation.success(response.data));
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.makeReservation.failure());
  }
}

function* getStations(action: ActionType<typeof actions.getStations.request>) {
  try {
    const { payload } = action;

    const response = yield call(request, {
      method: 'get',
      url: Endpoints.stationsPath(),
      params: payload,
    });

    yield call(resolvePromiseAction, action, response.data);
    yield put(actions.getStations.success(response.data));
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.getStations.failure());
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* handleFreeTicketsCallback(action: ActionType<typeof actions.freeTickets.request>): Generator<any, any, any> {
  const freeTickets: FreeTickets | null = yield select<(state: IStore) => FreeTickets | null>(
    (state) => state.trips.freeTickets,
  );

  if (!freeTickets) return;

  yield all(Object.entries(freeTickets).map(([key, value]) => call(handleUpdateTicket, key, value, action)));

  // eslint-disable-next-line consistent-return
  return {
    isFinished: true,
    keys: Object.keys(freeTickets),
  };
}

function* handleUpdateTicket(
  key: string,
  value: FreeTicketsPayload[],
  action: ActionType<typeof actions.freeTickets.request>,
) {
  if (!value) return;

  const userId = value.map((item) => item.userId);
  const tickets = value.map(({ ticket }) => ticket);

  const ticketsAttributes = tickets.map((ticket) => ({
    ...ticket,
    seatIdentifier: '',
    price: '0.0',
  }));

  try {
    yield call(request, {
      method: 'patch',
      url: Endpoints.tripPath(key),
      params: {
        userId,
        ticketsAttributes,
      },
    });
    yield all(tickets.map((ticket) => put(actions.cleanFreeTickets({ ticketId: ticket.id, tripId: key }))));
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.freeTickets.failure({ key }));
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* handleFreeTickets(action: ActionType<typeof actions.freeTickets.request>): Generator<any, any, any> {
  const payment: GetPaymentResponse | null = yield select<(state: IStore) => GetPaymentResponse | null>(
    (state) => state.payments.payment,
  );

  const [result] = yield all([call(handleFreeTicketsCallback, action)]);

  const { isFinished } = result;

  if (!payment) return;

  if (isFinished) {
    yield put(actions.getPayment.request({ id: payment.id }));
    yield take(actions.getPayment.success);
    yield put(actions.freeTickets.success());
    yield call(resolvePromiseAction, action);
  }
}


function* verifyTripPrice(action: ActionType<typeof actions.verifyTripPrice.request>) {
  try {
    const response = yield GipsyyGDSApi.get(Endpoints.verifyTripPricePath(), {
      method: 'get',
      params: action.payload,
    });

    yield call(resolvePromiseAction, action, response.data);
    yield put(actions.verifyTripPrice.success(response.data));
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.verifyTripPrice.failure());
  }
}

export const watchTrips = [
  takeLeading(actions.getTrip.request, getTrip),
  takeLeading(actions.getTrips.request, getTrips),
  takeLeading(actions.closeTrip.request, closeTrip),
  takeLeading(actions.deleteTrip.request, deleteTrip),
  takeLeading(actions.getTickets.request, getTickets),
  takeLeading(actions.createTrip.request, createTrip),
  takeLeading(actions.saveTickets.request, saveTickets),
  takeLeading(actions.getStations.request, getStations),
  takeLeading(actions.makeReservation.request, makeReservation),
  takeLeading(actions.verifyTripPrice.request, verifyTripPrice),
  debounce(1000, actions.freeTickets.request, handleFreeTickets),
];