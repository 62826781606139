import axios, { AxiosInstance } from 'axios';
import { GipsyInstanceType } from 'global';
import humps from 'humps';

// API
export const GipsyyApi = axios.create({
  baseURL:
    process.env.REACT_APP_INSTANCE_TYPE === GipsyInstanceType.BRAZIL
      ? process.env.REACT_APP_GDS_API_BR
      : process.env.REACT_APP_GDS_API_PT,
  transformResponse: [
    ...(axios.defaults.transformResponse as []),
    (data) => {
      if (data instanceof Blob) return data;
      return humps.camelizeKeys(data);
    },
  ],
});

// WEBAPI
const GipsyyGDSApi = axios.create({
  baseURL:
    process.env.REACT_APP_INSTANCE_TYPE === GipsyInstanceType.BRAZIL
      ? process.env.REACT_APP_GDS_WEB_API_BR
      : process.env.REACT_APP_GDS_WEB_API_PT,
  transformResponse: [
    ...(axios.defaults.transformResponse as []),
    (data) => {
      if (data instanceof Blob) return data;
      return humps.camelizeKeys(data);
    },
  ],
});

export default GipsyyGDSApi;
