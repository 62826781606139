import { createPromiseAction } from 'redux-saga-promise-actions';
import {
  CheckoutPayload,
  CheckoutResponse,
  CheckoutPayloadLinkResponse,
  CheckoutPayloadLink,
} from 'store/types/checkout';

export const makePaymentBR = createPromiseAction('MAKE_PAYMENT', 'MAKE_PAYMENT_SUCCESS', 'MAKE_PAYMENT_FAILURE')<
  CheckoutPayload,
  CheckoutResponse,
  undefined
>();

export const getInformationsLinkPaymentBR = createPromiseAction(
  'GET_PAYMENT_LINK',
  'GET_LINK_PAYMENT_LINK_SUCCESS',
  'GET_LINK_PAYMENT_LINK_FAILURE',
)<CheckoutPayloadLink, CheckoutPayloadLinkResponse, undefined>();
