import { createAsyncAction, createAction } from 'typesafe-actions';

import {
  GetLocationPayload,
  GetTopLocationsPayload,
  GetLocationsPayload,
  GetLocationsSucceededPayload,
} from 'store/types/locations';
import Location from 'models/Location';
import { createPromiseAction } from 'redux-saga-promise-actions';

export const getCurrentLocation = createAsyncAction(
  'GET_CURRENT_LOCATION',
  'GET_CURRENT_LOCATION_SUCCEEDED',
  'GET_CURRENT_LOCATION_FAILURE',
)<undefined, { latitude: number; longitude: number }, undefined>();

export const getOriginLocations = createAsyncAction(
  'GET_ORIGIN_LOCATIONS',
  'GET_ORIGIN_LOCATIONS_SUCCEEDED',
  'GET_ORIGIN_LOCATIONS_FAILURE',
)<GetLocationPayload, { locations: Location[] }, undefined>();

export const getDestinationLocations = createAsyncAction(
  'GET_DESTINATION_LOCATIONS',
  'GET_DESTINATION_LOCATIONS_SUCCEEDED',
  'GET_DESTINATION_LOCATIONS_FAILURE',
)<GetLocationPayload, { locations: Location[] }, undefined>();

export const getTopOriginLocations = createPromiseAction(
  'GET_TOP_ORIGIN_LOCATIONS',
  'GET_TOP_ORIGIN_LOCATIONS_SUCCEEDED',
  'GET_TOP_ORIGIN_LOCATIONS_FAILURE',
)<GetTopLocationsPayload, { locations: Location[] }, undefined>();

export const getTopDestinationLocations = createPromiseAction(
  'GET_TOP_DESTINATION_LOCATIONS',
  'GET_TOP_DESTINATION_LOCATIONS_SUCCEEDED',
  'GET_TOP_DESTINATION_LOCATIONS_FAILURE',
)<GetTopLocationsPayload, { locations: Location[] }, undefined>();

export const getLocations = createPromiseAction('GET_LOCATIONS', 'GET_LOCATIONS_SUCCEEDED', 'GET_LOCATIONS_FAILURE')<
  GetLocationsPayload,
  GetLocationsSucceededPayload,
  undefined
>();

export const swapLocations = createAction('SWAP_LOCATIONS')<undefined>();

export const saveOriginLocation = createAction('SAVE_ORIGIN_LOCATION', (location: Location | null) => ({
  location,
}))<{ location: Location | null }>();

export const saveDestinationLocation = createAction('SAVE_DESTINATION_LOCATION', (location: Location | null) => ({
  location,
}))<{ location: Location | null }>();

export const setFilterLocations = createAction('SET_FILTERED_DESTINATIONS', (locationsFiltered: Location[]) => ({
  locationsFiltered,
}))<{ locationsFiltered: Location[] }>();
