import { createAction } from 'typesafe-actions';
import { SearchFormPayload, SearchForm } from 'store/types/search';

export const addToSearchForm = createAction('ADD_TO_SEARCH_FORM', (newField: SearchFormPayload) => ({
  newField,
}))<{ newField: SearchFormPayload }>();

export const setPassengers = createAction('SET_PASSENGERS', (passengers: SearchForm['passengers']) => ({
  passengers,
}))<{ passengers: SearchForm['passengers'] }>();

export const setDepartDateTripsSearch = createAction('SET_DEPART_DATE', (date: string) => ({
  date,
}))<{ date: string }>();
