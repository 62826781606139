import React, { useEffect } from 'react';
import ReactGA, { FieldsObject } from 'react-ga';
import { RouteComponentProps } from 'react-router-dom';

ReactGA.initialize(process.env.REACT_APP_GA_ID!, { testMode: process.env.NODE_ENV === 'test' });

export default <P extends RouteComponentProps>(WrappedComponent: any, options: FieldsObject = {}) => {
  const trackPage = (page: string) => {
    ReactGA.set({ page, ...options });
    ReactGA.pageview(page);
  };

  return (props: P) => {
    const { location } = props;
    useEffect(() => {
      trackPage(location.pathname);
      if (location?.search.includes('gclid')) {
        const queryParams = new URLSearchParams(window.location.search);
        ReactGA.set({ campaignId: queryParams.get('gclid') });
      }
    }, [location.pathname]);

    return <WrappedComponent {...props} />;
  };
};
