import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from 'store/actions/banners';

import { Banner } from 'store/types/banners';

const initialState: Array<Banner> = [];

export const bannersReducer = createReducer<Array<Banner>, ActionType<typeof actions>>(initialState).handleAction(
  actions.getBanners.success,
  (state, { payload }) => ({
    ...state,
    ...payload,
  }),
);
