import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from 'store/actions/calendar';

import { Calendar } from 'store/types/calendar';

const initialState: Array<Calendar> = [];

export const CalendarReducer = createReducer<Array<Calendar>, ActionType<typeof actions>>(initialState).handleAction(
  actions.getCalendar.success,
  (state, { payload }) => ({
    ...state,
    ...payload,
  }),
);
