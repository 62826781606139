import { compose } from 'redux';

export enum GipsyInstanceType {
  BRAZIL = 'brazil',
  PORTUGAL = 'portugal',
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Intercom: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercomSettings: { [key: string]: any; app_id: string; language_override: string };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    opera: any;
  }
}
