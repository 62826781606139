import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import countries from 'i18n-iso-countries';
import Backend from 'i18next-http-backend';
import moment from 'moment';
import 'moment/locale/pt';
import 'moment/locale/es';
import 'moment/locale/pt-br';
import GipsyApi from 'apis/Gipsy';
import { parseEnvBoolean } from 'utils';

const supportedLngs = ['en', 'pt', 'pt-BR', 'es'];

let lng = process.env.REACT_APP_I18N_DEFAULT_LANGUAGE || 'pt';

if (window.location.href.indexOf('gipsyy.pt') > -1) {
  lng = 'pt';
}
if (window.location.href.indexOf('gipsyy.uk') > -1) {
  lng = 'en';
}
if (window.location.href.indexOf('gipsyy.es') > -1) {
  lng = 'es';
}

const storageLanguage = localStorage.getItem('language');
if (storageLanguage && supportedLngs.indexOf(storageLanguage) > -1) {
  lng = storageLanguage;
}

GipsyApi.defaults.params.locale = lng;

countries.registerLocale(require(`i18n-iso-countries/langs/en.json`));
countries.registerLocale(require(`i18n-iso-countries/langs/pt.json`));
countries.registerLocale(require(`i18n-iso-countries/langs/es.json`));

moment.locale(lng);

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng,
    supportedLngs,
    debug: parseEnvBoolean(process.env.REACT_APP_I18N_DEBUG_MODE),
    fallbackLng: 'pt',
    ns: ['views', 'models', 'general', 'validations', 'validationsMessages', 'seo', 'reactDateCalendar'],
    interpolation: { escapeValue: false },
    backend: {
      queryStringParams: { noCache: new Date().getTime() },
    },
  });

export default i18n;
