import { ActionType } from 'typesafe-actions';

import * as subscriptionActions from 'store/actions/subscription';
import { call, debounce, put, takeLeading } from 'redux-saga/effects';
import { SubscriberResponse } from 'store/types/subscription';
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions';
import Endpoints from 'endpoints';
import GipsyyGDSApi from 'apis/GipsyGDS';

function* addSubscriber(action: ActionType<typeof subscriptionActions.addSubscriber.request>) {
  const { name, email } = action.payload;

  try {
    const response: { data: SubscriberResponse } = yield GipsyyGDSApi.post(Endpoints.addSubscriber(), {
      name,
      email,
    });

    yield put(subscriptionActions.addSubscriber.success({ success: response.data.success }));
    yield call(resolvePromiseAction, action, { success: response.data.success });
  } catch (error) {
    yield put(subscriptionActions.addSubscriber.failure());
    yield call(rejectPromiseAction, action, error);
  }
}

function* updateSubscriber(action: ActionType<typeof subscriptionActions.updateSubscriber.request>) {
  const { email } = action.payload;

  try {
    const response: { data: SubscriberResponse } = yield GipsyyGDSApi.put(Endpoints.updateSubscriber(), {
      email,
    });

    yield put(subscriptionActions.updateSubscriber.success({ success: response.data.success }));
    yield call(resolvePromiseAction, action, { success: response.data.success });
  } catch (error) {
    yield put(subscriptionActions.updateSubscriber.failure());
    yield call(rejectPromiseAction, action, error);
  }
}

function* getSubscriber(action: ActionType<typeof subscriptionActions.getSubscriber.request>) {
  const { email } = action.payload;

  try {
    const response: { data: SubscriberResponse } = yield GipsyyGDSApi.get(Endpoints.getSubscriber(email));

    yield put(subscriptionActions.getSubscriber.success({ success: response.data.success }));
    yield call(resolvePromiseAction, action, { success: response.data.success });
  } catch (error) {
    yield put(subscriptionActions.getSubscriber.failure());
    yield call(rejectPromiseAction, action, error);
  }
}

export const watchSubscription = [
  takeLeading(subscriptionActions.addSubscriber.request, addSubscriber),
  takeLeading(subscriptionActions.updateSubscriber.request, updateSubscriber),
  debounce(1000, subscriptionActions.getSubscriber.request, getSubscriber),
];
