import { toast, ToastOptions } from 'react-toastify';

const bodyStyle: React.CSSProperties = { fontFamily: 'Poppins', fontSize: 14 };

const containerSuccessStyle: React.CSSProperties = { backgroundColor: '#ffb000' };

const containerErrorStyle: React.CSSProperties = { backgroundColor: '#ff1010' };

const containerInfoStyle: React.CSSProperties = { backgroundColor: '#0b4178' };

export const success = (content: string, options?: ToastOptions) =>
  toast.success(content, { style: containerSuccessStyle, bodyStyle, ...options });

export const error = (content: string, options?: ToastOptions) =>
  toast.error(content, { style: containerErrorStyle, bodyStyle, ...options });

export const info = (content: string, options?: ToastOptions) =>
  toast.info(content, { style: containerInfoStyle, bodyStyle, ...options });
