import { TopDestination } from 'store/types';
import Location from 'models/Location';
import countries from 'i18n-iso-countries';
import moment from 'moment';

export const getFullLocationName = (location: Location | null, language: string) =>
  location
    ? [location?.name, countries.getName(location.countryCode, language.substring(0, 2))].filter(Boolean).join(' - ')
    : '';

export const groupLocations = (list: Location[], keyGetter: (location: Location) => string) => {
  const map = new Map();

  list.forEach((item: Location) => {
    const key = keyGetter(item);
    const collection = map.get(key);

    map.set(key, [...(collection ?? []), item]);
  });

  return map;
};

export const normalizeAccents = (country: string | null): string =>
  country ? country.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '';

const departureDate2 = moment().toISOString();

export const getSearch = ({ idLocalityOrigin, idLocalityDestination }: TopDestination) =>
  `departureLocation=${idLocalityOrigin}&arrivalLocation=${idLocalityDestination}&departDate=${departureDate2}`;
