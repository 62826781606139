import { createAsyncAction } from 'typesafe-actions';
import { createPromiseAction } from 'redux-saga-promise-actions';
import { SearchHelpPagePayload } from 'store/types/static_pages';
import StaticPage from 'models/StaticPage';

export const getStaticPages = createAsyncAction(
  'GET_STATIC_PAGES',
  'GET_STATIC_PAGES_SUCCEEDED',
  'GET_STATIC_PAGES_FAILURE',
)<undefined, StaticPage[], undefined>();

export const searchHelpPage = createPromiseAction(
  'SEARCH_HELP_PAGE',
  'SEARCH_HELP_PAGE_SUCCEEDED',
  'SEARCH_HELP_PAGE_FAILURE',
)<SearchHelpPagePayload, StaticPage[], undefined>();
