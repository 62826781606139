import { ActionType, createReducer } from 'typesafe-actions';
import { SubscriberState } from 'store/types/subscription';
import * as subscriptionActions from 'store/actions/subscription';

const initialState: SubscriberState = {
  isSubscribed: false,
  email: '',
};

export const subscriptionReducer = createReducer<SubscriberState, ActionType<typeof subscriptionActions>>(initialState)
  .handleAction(subscriptionActions.getSubscriber.success, (state, { payload }) => ({
    ...state,
    isSubscribed: payload.success,
  }))
  .handleAction(subscriptionActions.addSubscriber.request, (state, { payload }) => ({
    ...state,
    email: payload.email,
  }))
  .handleAction(subscriptionActions.updateSubscriber.request, (state, { payload }) => ({
    ...state,
    email: payload.email,
  }));
