import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormikContext, getIn } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getOriginLocations, getDestinationLocations } from 'store/actions';
import countries from 'i18n-iso-countries';
import Location from 'models/Location';

import { ReactComponent as CloseIcon } from 'images/icons/iconfinder_x.svg';
import { ReactComponent as DestinationSvg } from 'images/search_engine/destination.svg';
import { ReactComponent as CitySvg } from 'images/search_engine/city.svg';
import { IStore } from 'store/reducers';

import { LostBookingIDValues } from '../index';

import styles from '../../index.module.css';

type LocationInputProps = {
  label: string;
  name: string;
  id?: string;
  type?: string;
  isOrigin?: boolean;
};

const getFullLocationName = (location: Location | null) =>
  [location?.cityName, location?.name].filter(Boolean).join(' - ');

const groupBy = (list: Location[], keyGetter: (location: Location) => string) => {
  const map = new Map();

  list.forEach((item: Location) => {
    const key = keyGetter(item);
    const collection = map.get(key);

    map.set(key, [...(collection ?? []), item]);
  });

  return map;
};

const LocationInput: React.VFC<LocationInputProps> = ({ label, isOrigin, name, id, type }) => {
  const { i18n } = useTranslation();
  const [value, setValue] = React.useState('');
  const dispatch = useDispatch();

  const { values, touched, errors, setFieldValue } = useFormikContext<LostBookingIDValues>();

  const [isSelectVisible, setIsSelectVisible] = React.useState(false);

  const locations = useSelector<IStore, Location[]>((state) =>
    isOrigin ? state.location.origins : state.location.destinations,
  );
  const groupedOptions = groupBy(locations, (location: Location) => location.cityName);
  const cities = Array.from(groupedOptions.keys());

  const inputRef = React.useRef<HTMLDivElement>(null);

  const handleSelectLocation = (selectedLocation: Location) => {
    setValue(selectedLocation.cityName);

    setFieldValue(name, selectedLocation.externalId);

    setIsSelectVisible(false);
  };

  const handleClearInput = () => {
    setValue('');

    setFieldValue(name, '');

    dispatch(
      isOrigin ? getOriginLocations.success({ locations: [] }) : getDestinationLocations.success({ locations: [] }),
    );
  };

  const handleCloseModal = (e: MouseEvent | TouchEvent) => {
    if (inputRef.current?.contains(e.target as Node)) return;

    setIsSelectVisible(false);
  };

  React.useEffect(() => {
    if (!value.length) return;

    dispatch(
      isOrigin
        ? getOriginLocations.request({ perPage: 20, name: value.trim() })
        : getDestinationLocations.request({ perPage: 20, name: value.trim() }),
    );
  }, [dispatch, value, isOrigin]);

  React.useEffect(() => {
    if (!value.length || !locations.length || (values.destination && values.origin)) return;

    setIsSelectVisible(true);
  }, [locations.length, value.length, values.destination, values.origin]);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleCloseModal);
    document.addEventListener('touchstart', handleCloseModal);

    return () => {
      document.removeEventListener('mousedown', handleCloseModal);
      document.removeEventListener('touchstart', handleCloseModal);
    };
  }, []);

  return (
    <div ref={inputRef}>
      <div
        className={cx('mt-4', styles.inputGroup, { [styles.errorBottom]: getIn(touched, name) && getIn(errors, name) })}
      >
        <label htmlFor={id} className={styles.inputUnderlined}>
          <input
            id={id}
            name={name}
            type={type}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className={cx({ [styles.presenceField]: value })}
            autoComplete="off"
          />
          <span className={styles.inputLabel}>{label}</span>
          {value && (
            <div className={styles.closeIconWrapper}>
              <CloseIcon className={styles.dateClearBtn} onClick={handleClearInput} />
            </div>
          )}
        </label>
        {isSelectVisible && (
          <div className={styles.select}>
            <div className={styles.options}>
              {cities.map((key) => (
                <React.Fragment key={key}>
                  <div className={styles.city}>
                    {groupedOptions.get(key).length > 1 ? (
                      <div>
                        <CitySvg />
                        <p title={groupedOptions.get(key)[0].cityName}>{groupedOptions.get(key)[0].cityName}</p>
                      </div>
                    ) : (
                      <div onClick={() => handleSelectLocation(groupedOptions.get(key)[0])}>
                        <DestinationSvg />
                        <p title={getFullLocationName(groupedOptions.get(key)[0])}>
                          {getFullLocationName(groupedOptions.get(key)[0])}
                        </p>
                      </div>
                    )}

                    <p className={styles.country}>
                      {`- ${countries.getName(groupedOptions.get(key)[0].countryCode, i18n.language.substring(0, 2))}`}
                    </p>
                  </div>

                  {groupedOptions.get(key).length > 1 && (
                    <div className={styles.locations}>
                      {groupedOptions.get(key).map((location: Location) => (
                        <div
                          key={location.externalId}
                          onClick={() => handleSelectLocation(location)}
                          className={styles.location}
                        >
                          <DestinationSvg />
                          <p title={location.name}>{location.name}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
      {getIn(touched, name) && getIn(errors, name) && <div className={styles.error}>{getIn(errors, name)}</div>}
    </div>
  );
};

export default LocationInput;
