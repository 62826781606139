import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from 'store/actions/trips';
import { TripsState } from 'store/types/trips';

import cloneDeep from 'lodash/cloneDeep';

const initialState: TripsState = {
  departureTrip: {
    isTripSelected: false,
    tripDetails: null,
    busModel: [],
  },
  returnTrip: {
    isTripSelected: false,
    tripDetails: null,
    busModel: [],
  },
  freeTickets: null,
};

export const tripsReducer = createReducer<TripsState, ActionType<typeof actions>>(initialState)
  .handleAction(actions.setIsTripSelected, (state, { payload }) => ({
    ...state,
    [payload.name]: {
      ...state[payload.name],
      isTripSelected: payload.isSelected,
    },
  }))
  .handleAction(actions.setTripDetails, (state, { payload }) => ({
    ...state,
    [payload.name]: {
      ...state[payload.name],
      tripDetails: payload.tripDetails,
    },
  }))
  .handleAction(actions.setBusModel, (state, { payload }) => {
    const busModelCopy = cloneDeep(state[payload.name].busModel);

    busModelCopy[payload.connectionIndex] = payload.connection;

    return {
      ...state,
      [payload.name]: {
        ...state[payload.name],
        busModel: busModelCopy,
      },
    };
  })
  .handleAction(actions.closeTrip.success, (state, { payload }) => {
    if (!payload.return) {
      return {
        ...state,
        departureTrip: {
          ...state.returnTrip,
          isTripSelected: false,
        },
        returnTrip: {
          busModel: [],
          isTripSelected: false,
          tripDetails: null,
        },
      };
    }

    return {
      ...state,
      returnTrip: {
        busModel: [],
        isTripSelected: false,
        tripDetails: null,
      },
    };
  })
  .handleAction(actions.freeTickets.request, (state, { payload }) => {
    const newTickets = state?.freeTickets?.[payload.tripId]
      ? [...state.freeTickets?.[payload.tripId], payload]
      : [payload];

    return {
      ...state,
      freeTickets: {
        ...state?.freeTickets,
        [payload.tripId]: newTickets,
      },
    };
  })
  .handleAction(actions.cleanFreeTickets, (state, { payload }) => {
    const tripTickets = state?.freeTickets?.[payload.tripId];

    if (tripTickets) {
      return {
        ...state,
        freeTickets: {
          ...state?.freeTickets,
          [payload.tripId]: tripTickets.filter((tripTicket) => tripTicket.ticket.id !== payload.ticketId),
        },
      };
    }

    return state;
  })
  .handleAction(actions.freeTickets.failure, (state, { payload }) => ({
    ...state,
    freeTickets: {
      ...state.freeTickets,
      [payload.key]: [],
    },
  }))
  .handleAction(actions.cleanTrips, () => ({
    ...initialState,
  }));
