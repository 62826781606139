export interface AuthState {
  sessionExpiration: string | null;
  token: string | null;
  refreshToken: string | null;
}

export enum DocumentType {
  CPF = 'cpf',
  PASSPORT = 'passport',
  ID = 'id',
}

export enum DocumentTypeId {
  ID = 1,
  FISCAL = 1,
  PASSPORT = 3,
  OTHER = 4,
}

export type TLoginPayload = {
  email: string;
  password: string;
};

export type TLoginSucceededPayload = {
  sessionExpiration: string;
  token: string;
  refreshToken: string;
  refreshTokenExpiryDate?: Date;
};

export type TLoginFailurePayload = {
  [key in keyof TLoginPayload]?: {
    [key: string]: string;
  };
} & {
  code: string;
};

export type SignUpPayload = {
  countryCode?: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  // phone?: string;
  // birthDate?: string;
  // documentType: DocumentTypeId;
  // documentNumber?: string;
  // addressLine1?: string;
  // addressLine2?: string;
  // zipCode?: string;
};

export type SignUpFailurePayload = {
  [key in keyof SignUpPayload]?: Array<string>;
} & {
  code: string;
};

// Verification Code

export type TVerificationCodePayload = {
  phone?: string;
  email?: string;
  confirmationCode: string;
};

export type TVerificationCodeFailurePayload = {
  [key in keyof TVerificationCodePayload]?: {
    [key: string]: string;
  };
} & {
  code: string;
};

export type TRemindPasswordPayload = {
  email: string;
};

export type TRemindPasswordFailurePayload = {
  [key in keyof TRemindPasswordPayload]?: {
    [key: string]: string;
  };
};

export type TConfirmRemindPasswordPayload = {
  email: string;
  password: string;
  validationCode: string;
};

export type TConfirmRemindPasswordFailurePayload = {
  [key in keyof TConfirmRemindPasswordPayload]?: {
    [key: string]: string;
  };
};

export type TMobileLoginPayload = {
  phone: string;
  confirmationCode: string | null;
};

export type TMobileLoginFailurePayload = {
  [key in keyof TMobileLoginPayload]?: {
    [key: string]: string;
  };
};
