import { createAsyncAction, createAction } from 'typesafe-actions';
import { createPromiseAction } from 'redux-saga-promise-actions';

import { User, UserLink } from 'models/User';

export const getUser = createPromiseAction('GET_USER', 'GET_USER_SUCCEEDED', 'GET_USER_FAILURE')<
  undefined,
  User,
  undefined
>();

export const setUser = createAction('SET_USER', (user: User | null | UserLink) => user)<User | null>();

export const restoreUserData = createAsyncAction(
  'RESTORE_USER_DATA',
  'RESTORE_USER_DATA_SUCCEEDED',
  'RESTORE_USER_DATA_FAILURE',
)<undefined, User, undefined>();
