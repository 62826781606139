import { createPromiseAction } from 'redux-saga-promise-actions';
import {
  AddSubscriberPayload,
  GetSubscriberPayload,
  SubscriberResponse,
  UpdateSubscriberPayload,
} from 'store/types/subscription';

export const addSubscriber = createPromiseAction(
  'ADD_SUBSCRIBER',
  'ADD_SUBSCRIBER_SUCCEEDED',
  'ADD_SUBSCRIBER_FAILURE',
)<AddSubscriberPayload, SubscriberResponse, undefined>();

export const updateSubscriber = createPromiseAction(
  'UPDATE_SUBSCRIBER',
  'UPDATE_SUBSCRIBER_SUCCEEDED',
  'UPDATE_SUBSCRIBER_FAILURE',
)<UpdateSubscriberPayload, SubscriberResponse, undefined>();

export const getSubscriber = createPromiseAction(
  'GET_SUBSCRIBER',
  'GET_SUBSCRIBER_SUCCEEDED',
  'GET_SUBSCRIBER_FAILURE',
)<GetSubscriberPayload, SubscriberResponse, undefined>();
