import { ActionType } from 'typesafe-actions';

import * as SetCustomerActions from 'store/actions/setCustomer';
import { call, put, takeLeading } from 'redux-saga/effects';
import { resolvePromiseAction } from 'redux-saga-promise-actions';
import Endpoints from 'endpoints';
import GipsyyGDSApi from 'apis/GipsyGDS';

function* SetCustomerCode(action: ActionType<typeof SetCustomerActions.applySetCustomer.request>) {
  const { customerId, paymentId } = action.payload;
  try {
    yield GipsyyGDSApi.patch(Endpoints.applysetCustomer(), {
      paymentId,
      customerId,
    });
    yield put(SetCustomerActions.applySetCustomer.success());
    yield call(resolvePromiseAction, action);
  } catch (error) {
    yield put(SetCustomerActions.applySetCustomer.failure());
    yield call(resolvePromiseAction, action);
  }
}

export const watchSetCustomer = [takeLeading(SetCustomerActions.applySetCustomer.request, SetCustomerCode)];
