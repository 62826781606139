import cx from 'classnames';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { AppViews } from 'endpoints';
import { ReactComponent as ChevronUp } from 'images/icons/chevron-down.svg';
import logoImg from 'images/logos/gipsyy.svg';

import { Tab } from 'components/auth/ManageYourBooking';
import { User } from 'models/User';
import { useSelector } from 'react-redux';
import { IStore } from 'store/reducers';
import styles from './Header.module.css';

import LanguageSelect from './LanguageSelect';
import UserMenu from './UserMenu';

interface Props {
  handleShowSidebar: () => void;
}

const Header: FC<Props> = ({ handleShowSidebar }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentUser = useSelector<IStore, User | null>((state: IStore) => state.users.currentUser);

  const isCheckoutPage = useMemo(() => {
    return location.pathname.indexOf('checkout') > 0;
  }, [location]);

  const isCurrentPage = (pathname: string) => window.location.pathname === pathname;

  return (
    <div className="container">
      <div className={styles.mainMenu}>
        <div className={styles.leftSection}>
          {!isCheckoutPage && (
            <button
              onClick={handleShowSidebar}
              type="button"
              data-testid="menu"
              id="menu"
              className={styles.menuButton}
            >
              <span />
              <span />
              <span />
            </button>
          )}
          <Link to={{ pathname: AppViews.HOME }} className={styles.desktopLogo}>
            <img src={logoImg} alt="Gipsyy logo" className={styles.logo} />
          </Link>
        </div>

        <span className={styles.centerSection}>
          <Link to={{ pathname: AppViews.HOME }} className={styles.mobileLogo}>
            <img src={logoImg} alt="Gipsyy logo" className={styles.logo} />
          </Link>
          {!isCheckoutPage && (
            <nav className={styles.desktopMenu}>
              <Link
                className={cx({ [styles.currentLink]: isCurrentPage(AppViews.HOME) })}
                to={{ pathname: AppViews.HOME }}
              >
                {t('views:sidebar.buy')}
              </Link>

              <Link
                className={cx({
                  [styles.currentLink]:
                    isCurrentPage(AppViews.USER_BOOKINGS) || isCurrentPage(AppViews.MANAGE_YOUR_BOOKING),
                })}
                to={
                  currentUser?.firstName
                    ? { pathname: AppViews.USER_BOOKINGS, state: { tab: Tab.haveBookingId } }
                    : { pathname: AppViews.MANAGE_YOUR_BOOKING }
                }
              >
                {t('views:sidebar.booking')}
              </Link>

              <div className={styles.menuItem}>
                <div className={styles.menuTrigger}>
                  {t('views:sidebar.plan')}
                  <ChevronUp className={styles.arrowDown} />
                </div>
                <div className={styles.menuSubitems}>
                  <Link
                    className={cx({ [styles.currentLink]: isCurrentPage(AppViews.ROADMAP) })}
                    to={{ pathname: AppViews.ROADMAP }}
                  >
                    {t('views:sidebar.roadmap')}
                  </Link>
                  <Link
                    className={cx({ [styles.currentLink]: isCurrentPage(AppViews.DESTINATIONS) })}
                    to={{ pathname: AppViews.DESTINATIONS }}
                    target="_blank"
                  >
                    {t('views:sidebar.destinations')}
                  </Link>
                  <Link
                    className={cx({ [styles.currentLink]: isCurrentPage(AppViews.STOPS_INFO) })}
                    to={{ pathname: AppViews.STOPS_INFO }}
                  >
                    {t('views:header.stopsInformation')}
                  </Link>
                </div>
              </div>

              <div className={styles.menuItem}>
                <div className={styles.menuTrigger}>
                  {t('views:sidebar.services')}
                  <ChevronUp className={styles.arrowDown} />
                </div>
                <div className={styles.menuSubitems}>
                  <Link
                    className={cx({ [styles.currentLink]: isCurrentPage(AppViews.ALUGUERES) })}
                    to={{ pathname: AppViews.ALUGUERES }}
                  >
                    {t('views:sidebar.rental')}
                  </Link>
                  <Link
                    className={cx({ [styles.currentLink]: isCurrentPage(AppViews.BAGGAGE) })}
                    to={{ pathname: AppViews.BAGGAGE }}
                  >
                    {t('views:sidebar.baggage')}
                  </Link>
                  <Link
                    className={cx({ [styles.currentLink]: isCurrentPage(AppViews.ANIMAL_TRANSPORT) })}
                    to={{ pathname: AppViews.ANIMAL_TRANSPORT }}
                  >
                    {t('views:sidebar.animal')}
                  </Link>
                </div>
              </div>

              <Link
                className={cx({ [styles.currentLink]: isCurrentPage(AppViews.BLOG) })}
                to={{ pathname: AppViews.BLOG }}
                target="_blank"
              >
                {t('views:sidebar.blog')}
              </Link>

              <Link
                className={cx({ [styles.currentLink]: isCurrentPage(AppViews.ABOUT) })}
                to={{ pathname: AppViews.ABOUT }}
              >
                {t('views:sidebar.about')}
              </Link>
            </nav>
          )}
        </span>

        <span className={styles.rightSection}>
          {!isCheckoutPage && (
            <>
              <LanguageSelect />
              <Link to={{ pathname: AppViews.HELP }} className={styles.help}>
                {t('views:header.help')}
              </Link>
              <UserMenu />
            </>
          )}
        </span>
      </div>
    </div>
  );
};

export default Header;
