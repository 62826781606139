import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { TicketsAttributes, PaymentTrip, PassengersDetails, DocumentType } from 'store/types';
import { PassengerInformation, PassengerInformationBR } from 'components/checkout/models/checkout.model';

export function createTicketsAttributes(
  trips: PaymentTrip[],
  passengersInformation: PassengerInformation[],
): TicketsAttributes[] {
  return trips.flatMap((trip) => {
    const grouped = groupBy(trip.tickets, (ticket) => ticket.connectionIndex);

    return Object.values(grouped).flatMap((tickets) =>
      sortBy(tickets, ['passengerType', 'id']).map((ticket, i) => ({
        id: ticket.id,
        passengerName: `${passengersInformation[i].firstName} ${passengersInformation[i].lastName}`,
        passengerDocumentType: passengersInformation[i].passengerDocumentType,
        passengerDocumentNumber: passengersInformation[i].passengerDocumentNumber,
      })),
    );
  });
}

export const getPassengersInformation = (
  trips: PaymentTrip[],
  passengersInformation: PassengerInformationBR[],
): PassengersDetails[] => {
  return trips.flatMap((trip) => {
    const grouped = groupBy(trip.tickets, (ticket) => ticket.connectionIndex);

    return Object.values(grouped).flatMap((tickets) =>
      sortBy(tickets, ['passengerType', 'id']).map((ticket, i) => ({
        ticketId: ticket.id,
        seatIdentifier: '',
        firstName: passengersInformation[i].firstName,
        lastName: passengersInformation[i].lastName,
        documentNumber: passengersInformation[i].documentNumber,
        documentType: DocumentType.CPF,
      })),
    );
  });
};
