import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from 'store/actions/auth';
import { AuthState } from 'store/types/auth';

const authData = JSON.parse(localStorage.getItem('authData') || '{}');

const initialState: AuthState = {
  sessionExpiration: authData?.sessionExpiration || null,
  token: authData?.token || null,
  refreshToken: authData?.refreshToken || null,
};

export const authReducer = createReducer<AuthState, ActionType<typeof actions>>(initialState)
  .handleAction(
    [
      actions.loginRequest.success,
      actions.refreshToken.success,
      actions.mobileLoginRequest.success,
      actions.setTokenPayload,
    ],
    (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  )
  .handleAction(actions.logout.success, (state) => ({
    ...state,
    sessionExpiration: null,
    token: null,
    refreshToken: null,
  }));
