import { ActionType } from 'typesafe-actions';
import { call, put, select, take, takeLeading } from 'redux-saga/effects';
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions';

import * as actions from 'store/actions/users';
import { IStore } from 'store/reducers';
import { User } from 'models/User';
import Endpoints from 'endpoints';

import { request } from './api';

function* getUser(action: ActionType<typeof actions.getUser.request>) {
  try {
    const response = yield call(request, {
      method: 'get',
      url: Endpoints.accountDetailsPath(),
    });

    localStorage.setItem('userData', JSON.stringify(response.data));

    yield call(resolvePromiseAction, action, response.data);
    yield put(actions.getUser.success(response.data));
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.getUser.failure());
  }
}

function* restoreUserData() {
  const token = yield select((state: IStore) => state.auth.token);

  if (!token) {
    localStorage.removeItem('userData');
    return;
  }

  const userData: string | null = localStorage.getItem('userData');
  let user: User | null;

  if (!userData) {
    user = null;
  } else {
    user = JSON.parse(userData) as User;
  }

  if (user) {
    yield put(actions.getUser.success(user));
  } else {
    yield put(actions.getUser.request());
    yield take(actions.getUser.success);
  }
}

export const watchUser = [
  takeLeading(actions.getUser.request, getUser),
  takeLeading(actions.restoreUserData.request, restoreUserData),
];
