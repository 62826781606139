import { createPromiseAction } from 'redux-saga-promise-actions';
import { DiscountCodeResponse, DiscountCodePayload, DiscountCodeError } from 'store/types/discountCode';

export const validateDiscountCode = createPromiseAction('IS_CODE_VALID', 'CODE_VALID', 'CODE_INVALID')<
  DiscountCodePayload,
  DiscountCodeResponse,
  DiscountCodeError
>();

export const applyDiscountCode = createPromiseAction('IS_CODE_CONFIRMED', 'CODE_CONFIRMED', 'CODE_NOT_CONFIRMED')<
  DiscountCodePayload,
  undefined,
  undefined
>();
