import { ActionType } from 'typesafe-actions';
import * as actions from 'store/actions/routes';
import { call, put, takeEvery } from 'redux-saga/effects';
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions';
import Endpoints from 'endpoints';
import { RouteFailure, RouteResponse } from 'store/types';
import GipsyyGDSApi from 'apis/GipsyGDS';

function* getRoutes(action: ActionType<typeof actions.getRoute.request>) {
  const { id } = action.payload;
  try {
    const response: { data: RouteResponse } = yield GipsyyGDSApi.get(Endpoints.getRoutes, {
      params: {
        id,
      },
    });

    yield call(resolvePromiseAction, action, response.data);
    yield put(actions.getRoute.success(response.data));
  } catch (error) {
    const { value } = error as RouteFailure;
    yield put(actions.getRoute.failure({ value }));
    yield call(rejectPromiseAction, action, error);
  }
}

export const watchRoutes = [takeEvery(actions.getRoute.request, getRoutes)];
