import { ActionType } from 'typesafe-actions';
import { call, put, debounce } from 'redux-saga/effects';
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions';
import { request } from 'store/sagas/api';

import * as actions from 'store/actions/cities';
import Endpoints from 'endpoints';

function* getCities(action: ActionType<typeof actions.getCities.request>) {
  const { cityName, countryCode } = action.payload;

  try {
    const response = yield call(request, {
      method: 'get',
      url: Endpoints.citiesPath(),
      params: {
        cityName,
        countryCode,
      },
    });

    yield put(actions.getCities.success({ cities: response.data.cities || [] }));
    yield call(resolvePromiseAction, action, { cities: response.data.cities || [] });
  } catch (err) {
    yield put(actions.getCities.failure());
    yield call(rejectPromiseAction, action, err);
  }
}

export const watchCities = [debounce(500, actions.getCities.request, getCities)];
