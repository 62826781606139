import { call, put, takeLeading, take, delay, race, takeLatest } from 'redux-saga/effects';

import { ActionType } from 'typesafe-actions';
import { resolvePromiseAction, rejectPromiseAction } from 'redux-saga-promise-actions';

import { CreditCardPaymentMethod, GetPaymentResponse, PaymentDetailsData } from 'store/types';
import * as actions from 'store/actions/payments';
import Endpoints from 'endpoints';
import GipsyApi from 'apis/Gipsy';
import { AxiosError } from 'axios';
import { request } from './api';

function* makePayment(action: ActionType<typeof actions.makePayment.request>) {
  try {
    const {
      payload: { id, ticketsAttributes, ...rest },
    } = action;

    const response: { data: GetPaymentResponse } = yield call(request, {
      method: 'patch',
      url: Endpoints.paymentPath(id),
      params: {
        trip: {
          ticketsAttributes,
        },
        ...rest,
      },
    });

    yield call(resolvePromiseAction, action, response.data);
    yield put(actions.makePayment.success(response.data));
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.makePayment.failure());
  }
}

function* getPayment(action: ActionType<typeof actions.getPayment.request>) {
  try {
    const { id } = action.payload;

    const response: { data: GetPaymentResponse } = yield GipsyApi.get(Endpoints.paymentPath(id));
    yield call(resolvePromiseAction, action, response.data);
    yield put(actions.getPayment.success(response.data));
  } catch (error) {
    const { response } = error as AxiosError;
    if (response?.data?.status >= 400 && response?.data?.status < 500) {
      yield put(actions.stopPaymentStatusTask());
    }

    yield call(rejectPromiseAction, action, error);
    yield put(actions.getPayment.failure());
  }
}

function* getPayments(action: ActionType<typeof actions.getPayments.request>) {
  try {
    const { type } = action.payload;
    const response: { data: Array<GetPaymentResponse> } = yield call(
      request,
      {
        method: 'GET',
        url: Endpoints.paymentsPath(type),
        // params: { type },
      },
      true,
    );
    yield call(resolvePromiseAction, action, response.data);
    yield put(actions.getPayments.success(response));
  } catch (error) {
    yield call(rejectPromiseAction, action, error);
    yield put(actions.getPayments.failure());
  }
}

function* startPaymentStatusTask(action: ActionType<typeof actions.startPaymentStatusTask>) {
  yield race({
    task: call(getPaymentStatusPeriodically, action.payload.id),
    cancel: take(actions.stopPaymentStatusTask),
  });
}

function* getPaymentStatusPeriodically(id: string) {
  while (true) {
    yield delay(parseInt(process.env.REACT_APP_PAYMENT_STATUS_REFRESH_TIME ?? '', 10) || 5000);
    yield put(actions.getPayment.request({ id }));
  }
}

function* getPaymentMethods(action: ActionType<typeof actions.getPaymentMethods.request>) {
  try {
    const response: { data: Array<CreditCardPaymentMethod> } = yield call(request, {
      method: 'get',
      url: Endpoints.paymentMethodsPath(),
    });

    yield call(resolvePromiseAction, action, response.data);
    yield put(actions.getPaymentMethods.success(response.data));
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.getPaymentMethods.failure());
  }
}

function* addPaymentMethod(action: ActionType<typeof actions.addPaymentMethod.request>) {
  try {
    const response: { data: CreditCardPaymentMethod | PaymentDetailsData } = yield call(request, {
      method: 'post',
      url: Endpoints.paymentMethodsPath(),
      params: action.payload,
    });

    yield put(actions.getPaymentMethods.request());
    yield take(actions.getPaymentMethods.success);
    yield call(resolvePromiseAction, action, response.data);
    yield put(actions.addPaymentMethod.success(response.data));
  } catch (error) {
    yield call(rejectPromiseAction, action, error);
    yield put(actions.addPaymentMethod.failure());
  }
}

function* changeDefaultCreditCard(action: ActionType<typeof actions.changeDefaultCreditCard.request>) {
  try {
    const { id } = action.payload;

    yield call(request, {
      method: 'patch',
      url: Endpoints.paymentMethodPath(id),
    });

    yield put(actions.getPaymentMethods.request());
    yield take(actions.getPaymentMethods.success);
    yield call(resolvePromiseAction, action);
    yield put(actions.changeDefaultCreditCard.success());
  } catch (error) {
    yield call(rejectPromiseAction, action, error);
    yield put(actions.changeDefaultCreditCard.failure());
  }
}

function* deletePaymentMethod(action: ActionType<typeof actions.deletePaymentMethod.request>) {
  try {
    const { id } = action.payload;

    GipsyApi.delete(Endpoints.paymentMethodPath(id));

    yield call(resolvePromiseAction, action);
    yield put(actions.deletePaymentMethod.success());
    yield put(actions.getPaymentMethods.request());
    yield take(actions.getPaymentMethods.success);
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.deletePaymentMethod.failure());
  }
}

export const watchPayments = [
  takeLeading(actions.getPayment.request, getPayment),
  takeLeading(actions.makePayment.request, makePayment),
  takeLeading(actions.getPayments.request, getPayments),
  takeLeading(actions.getPaymentMethods.request, getPaymentMethods),
  takeLatest(actions.startPaymentStatusTask, startPaymentStatusTask),
  takeLeading(actions.deletePaymentMethod.request, deletePaymentMethod),
  takeLeading(actions.addPaymentMethod.request, addPaymentMethod),
  takeLeading(actions.changeDefaultCreditCard.request, changeDefaultCreditCard),
];
