import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from 'store/actions';
import { UserState } from 'store/types/users';

const initialState: UserState = {
  currentUser: null,
};

export const usersReducer = createReducer<UserState, ActionType<typeof actions>>(initialState)
  .handleAction([actions.getUser.success, actions.setUser], (state, { payload }) => ({
    ...state,
    currentUser: payload,
  }))
  .handleAction(actions.logout.success, () => ({
    ...initialState,
  }));
