import { createPromiseAction } from 'redux-saga-promise-actions';
import {
  CreateTripPayload,
  GetTripPayload,
  GetTripsPayload,
  MakeReservationPayload,
  TripResponse,
  Trip,
  FreeTicketsPayload,
  GetTicketsPayload,
  Ticket,
  DeleteTripPayload,
  DeleteTripResponse,
  CleanFreeTicketsPayload,
  TripName,
  Connection,
  GetStationsPayload,
  Station,
  VerifyTripPricePayload,
  VerifyTripPriceResponse,
} from 'store/types/trips';
import { createAction } from 'typesafe-actions';

export const setIsTripSelected = createAction('SET_IS_TRIP_SELECTED', (name: TripName, isSelected: boolean) => ({
  name,
  isSelected,
}))<{ name: TripName; isSelected: boolean }>();

export const cleanTrips = createAction('CLEAN_TRIPS')<undefined>();

export const setTripDetails = createAction('SET_TRIP_DETAILS', (name: TripName, tripDetails: TripResponse | null) => ({
  name,
  tripDetails,
}))<{ name: TripName; tripDetails: TripResponse | null }>();

export const setBusModel = createAction(
  'SET_BUS_MODEL',
  (name: TripName, connection: Connection, connectionIndex: number) => ({
    connection,
    name,
    connectionIndex,
  }),
)<{ name: TripName; connection: Connection; connectionIndex: number }>();

/**
 * Requests
 */

export const createTrip = createPromiseAction('CREATE_TRIP', 'CREATE_TRIP_SUCCEEDED', 'CREATE_TRIP_FAILURE')<
  CreateTripPayload,
  TripResponse,
  undefined
>();

export const getTrip = createPromiseAction('GET_TRIP', 'GET_TRIP_SUCCEEDED', 'GET_TRIP_FAILURE')<
  GetTripPayload,
  TripResponse,
  undefined
>();

export const getTrips = createPromiseAction('GET_TRIPS', 'GET_TRIPS_SUCCEEDED', 'GET_TRIPS_FAILURE')<
  GetTripsPayload,
  Trip[],
  undefined
>();

export const getTickets = createPromiseAction('GET_TICKETS', 'GET_TICKETS_SUCCEEDED', 'GET_TICKETS_FAILURE')<
  GetTicketsPayload,
  Ticket[],
  undefined
>();

export const saveTickets = createPromiseAction('SAVE_TICKETS', 'SAVE_TICKETS_SUCCEEDED', 'SAVE_TICKETS_FAILURE')<
  GetTicketsPayload,
  { fileURL: string },
  undefined
>();

export const deleteTrip = createPromiseAction('DELETE_TRIP', 'DELETE_TRIP_SUCCEEDED', 'DELETE_TRIP_FAILURE')<
  DeleteTripPayload,
  DeleteTripResponse,
  undefined
>();

export const closeTrip = createPromiseAction('CLOSE_TRIP', 'CLOSE_TRIP_SUCCEEDED', 'CLOSE_TRIP_FAILURE')<
  DeleteTripPayload,
  DeleteTripResponse,
  undefined
>();

export const getStations = createPromiseAction('GET_STATIONS', 'GET_STATIONS_SUCCEEDED', 'GET_STATIONS_FAILURE')<
  GetStationsPayload,
  Station[],
  undefined
>();

export const makeReservation = createPromiseAction(
  'MAKE_RESERVATION',
  'MAKE_RESERVATION_SUCCEEDED',
  'MAKE_RESERVATION_FAILURE',
)<MakeReservationPayload, TripResponse, undefined>();

export const freeTickets = createPromiseAction('FREE_TICKETS', 'FREE_TICKETS_SUCCEEDED', 'FREE_TICKETS_FAILURE')<
  FreeTicketsPayload,
  undefined,
  { key: string }
>();

export const cleanFreeTickets = createAction('CLEAN_FREE_TICKETS', ({ tripId, ticketId }: CleanFreeTicketsPayload) => ({
  tripId,
  ticketId,
}))<CleanFreeTicketsPayload>();

export const verifyTripPrice = createPromiseAction(
  'VERIFY_TRIP_PRICE',
  'VERIFY_TRIP_PRICE_SUCCEEDED',
  'VERIFY_TRIP_PRICE_FAILURE',
)<VerifyTripPricePayload, VerifyTripPriceResponse, undefined>();

