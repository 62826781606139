import { ActionType } from 'typesafe-actions';
import * as actions from 'store/actions/nextavailabletrips';
import { call, put, takeEvery } from 'redux-saga/effects';
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions';
import Endpoints from 'endpoints';
import { NextAvailableTripsResponse } from 'store/types';
import GipsyyGDSApi from 'apis/GipsyGDS';

function* getNextAvailableTrips(action: ActionType<typeof actions.getNextAvailableTrips.request>) {
  try {
    const response: { data: NextAvailableTripsResponse } = yield GipsyyGDSApi.post(Endpoints.getNextAvailableTrips, {
      ...action.payload,
    });
    yield call(resolvePromiseAction, action, response.data.data);
    yield put(actions.getNextAvailableTrips.success(response.data.data));
  } catch (error) {
    yield put(actions.getNextAvailableTrips.failure({ value: error }));
    yield call(rejectPromiseAction, action, error);
  }
}

export const watchNextAvailableTrips = [takeEvery(actions.getNextAvailableTrips.request, getNextAvailableTrips)];
