import { call, put, select, take } from 'redux-saga/effects';

// Types
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { IStore } from 'store/reducers';

// Actions
import * as actions from 'store/actions/auth';

// Utils
import GipsyAPI from 'apis/Gipsy';
import GipsyyGDSApi from 'apis/GipsyGDS';

export function* request<T>(
  config: AxiosRequestConfig,
  isGDS: boolean = false,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<any, AxiosResponse<T>, any> {
  const accessToken = yield select((state: IStore) => state.auth.token);

  const api = isGDS ? GipsyyGDSApi : GipsyAPI;

  const axiosConfig: AxiosRequestConfig = {
    ...config,
    headers: {
      ...(config.headers ?? {}),
      Authorization: isGDS ? `Bearer ${accessToken}` : accessToken,
    },
  };

  try {
    return yield call(api.request, axiosConfig);
  } catch (err) {
    if (err.response?.status === 401) {
      if (config.params?.user?.refreshToken) {
        yield put(actions.refreshToken.failure());
        yield put(actions.logout.request());
        yield take(actions.logout.success);
      }

      yield put(actions.refreshToken.request());
      yield take(actions.refreshToken.success);
      return yield call(request, config);
    }

    throw err;
  }
}

// export function* requestGDS<T>(
//   config: AxiosRequestConfig,
//   environment: GDSEnvs,
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
// ): Generator<any, AxiosResponse<T>, any> {
//   const accessToken = yield select((state: IStore) => state.auth.token);

//   try {
//     return yield call(gdsApi.request, {
//       ...config,
//       headers: {
//         ...(config.headers ?? {}),
//         Authorization: accessToken,
//       },
//     });
//   } catch (err) {
//     if (err.response?.status === 401) {
//       if (config.params?.user?.refreshToken) {
//         yield put(actions.refreshToken.failure());
//         yield put(actions.logout.request());
//         yield take(actions.logout.success);
//       }

//       yield put(actions.refreshToken.request());
//       yield take(actions.refreshToken.success);
//       return yield call(request, config);
//     }

//     throw err;
//   }
// }
