export function trimValues<T extends object>(values: T): T {
  if (typeof values !== 'object' || values === null) {
    return values;
  }

  return Object.fromEntries(
    Object.entries(values).map(([key, value]) => {
      if (typeof value === 'object' || value instanceof Object) {
        return [key, trimValues(value)];
      }

      if (typeof value === 'string' || value instanceof String) {
        return [key, value.trim()];
      }

      return [key, value];
    }),
  ) as T;
}
