import { createPromiseAction } from 'redux-saga-promise-actions';

import {
  MakePaymentPayload,
  CreditCardPaymentMethod,
  TripPayment,
  GetPaymentResponse,
  PaymentPayload,
  AddCreditCardPayload,
  PaymentDetailsData,
  PaymentsPayload,
} from 'store/types/payments';
import { createAction } from 'typesafe-actions';

export const getPayment = createPromiseAction('GET_PAYMENT', 'GET_PAYMENT_SUCCEEDED', 'GET_PAYMENT_FAILURE')<
  PaymentPayload,
  GetPaymentResponse,
  undefined
>();

export const getPayments = createPromiseAction('GET_PAYMENTS', 'GET_PAYMENTS_SUCCEEDED', 'GET_PAYMENTS_FAILURE')<
  PaymentsPayload,
  { data: GetPaymentResponse[] },
  undefined
>();

export const deletePaymentMethod = createPromiseAction(
  'DELETE_PAYMENT_METHOD',
  'DELETE_PAYMENT_METHOD_SUCCEEDED',
  'DELETE_PAYMENT_METHOD_FAILURE',
)<PaymentPayload, undefined, undefined>();

export const changeDefaultCreditCard = createPromiseAction(
  'CHANGE_DEFAULT_CREDIT_CARD',
  'CHANGE_DEFAULT_CREDIT_CARD_SUCCEEDED',
  'CHANGE_DEFAULT_CREDIT_CARD_FAILURE',
)<PaymentPayload, undefined, undefined>();

export const startPaymentStatusTask = createAction('START_SYNC_PAYMENT_STATUS_TASK', (id: string) => ({
  id,
}))<PaymentPayload>();

export const stopPaymentStatusTask = createAction('STOP_SYNC_PAYMENT_STATUS_TASK')<undefined>();

export const makePayment = createPromiseAction(
  'MAKE_PAYMENT_REQUEST',
  'MAKE_PAYMENT_SUCCEEDED',
  'MAKE_PAYMENT_FAILURE',
)<MakePaymentPayload, GetPaymentResponse, undefined>();

export const addPaymentMethod = createPromiseAction(
  'ADD_PAYMENT_METHOD',
  'ADD_PAYMENT_METHOD_SUCCEEDED',
  'ADD_PAYMENT_METHOD_FAILURE',
)<AddCreditCardPayload, CreditCardPaymentMethod | PaymentDetailsData, undefined>();

export const getPaymentMethods = createPromiseAction(
  'GET_PAYMENT_METHODS',
  'GET_PAYMENT_METHODS_SUCCEEDED',
  'GET_PAYMENT_METHODS_FAILURE',
)<undefined, CreditCardPaymentMethod[], undefined>();

export const setTripPayment = createAction('SET_TRIP_PAYMENT', (payment: TripPayment) => ({
  payment,
}))<{ payment: TripPayment }>();

export const setPayment = createAction('SET_PAYMENT', (payment: GetPaymentResponse) => ({
  payment,
}))<{ payment: GetPaymentResponse }>();
