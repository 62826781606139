import { ActionType } from 'typesafe-actions';

import * as actions from 'store/actions/topDestinations';
import { call, put, takeEvery } from 'redux-saga/effects';
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions';
import Endpoints from 'endpoints';
import { TopDestinationsResponse } from 'store/types';
import { GipsyInstanceType } from 'global';
import GipsyyGDSApi from 'apis/GipsyGDS';

function* getTopDestinations(action: ActionType<typeof actions.getTopDestinations.request>) {
  const countryId = process.env.REACT_APP_INSTANCE_TYPE === GipsyInstanceType.PORTUGAL ? 1 : 2;
  try {
    const response: { data: TopDestinationsResponse } = yield GipsyyGDSApi.get(Endpoints.getTopDestinations(countryId));
    yield call(resolvePromiseAction, action, response.data.data);
    yield put(actions.getTopDestinations.success(response.data.data));
  } catch (error) {
    yield put(actions.getTopDestinations.failure({ value: error }));
    yield call(rejectPromiseAction, action, error);
  }
}

export const watchTopDestinations = [takeEvery(actions.getTopDestinations.request, getTopDestinations)];
