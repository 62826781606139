import { all } from 'redux-saga/effects';

import { watchAuth } from './auth';
import { watchFaqs } from './faqs';
import { watchLocations } from './locations';
import { watchNewsletter } from './newsletter';
import { watchStaticPages } from './static_pages';
import { watchUser } from './users';
import { watchPayments } from './payments';
import { watchTrips } from './trips';
import { watchCities } from './cities';
import { watchDiscountCode } from './discountCode';
import { watchSubscription } from './subscription';
import { watchNextAvailableTrips } from './nextavailabletrips';
import { watchTopDestinations } from './topDestinations';
import { watchCheckout } from './checkout';
import { watchQrCodes } from './qrcode';
import { watchPaymentData } from './paymentdata';
import { watchBanner } from './banners';
import { watchCalendar } from './calendar';
import { watchRoutes } from './routes';
import { watchSetCustomer } from './setCustomer';

export default function* rootSaga() {
  yield all([
    ...watchFaqs,
    ...watchLocations,
    ...watchStaticPages,
    ...watchAuth,
    ...watchUser,
    ...watchNewsletter,
    ...watchPayments,
    ...watchTrips,
    ...watchCities,
    ...watchDiscountCode,
    ...watchSubscription,
    ...watchNextAvailableTrips,
    ...watchTopDestinations,
    ...watchCheckout,
    ...watchQrCodes,
    ...watchPaymentData,
    ...watchBanner,
    ...watchCalendar,
    ...watchRoutes,
    ...watchSetCustomer,
  ]);
}
