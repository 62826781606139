import { AxiosError } from 'axios';
import { createAsyncAction, createAction } from 'typesafe-actions';
import { createPromiseAction } from 'redux-saga-promise-actions';
import {
  TVerificationCodePayload,
  TVerificationCodeFailurePayload,
  TLoginPayload,
  SignUpPayload,
  SignUpFailurePayload,
  TLoginSucceededPayload,
  TLoginFailurePayload,
  TRemindPasswordPayload,
  TRemindPasswordFailurePayload,
  TConfirmRemindPasswordPayload,
  TConfirmRemindPasswordFailurePayload,
  TMobileLoginPayload,
  TMobileLoginFailurePayload,
} from 'store/types/auth';

export const loginRequest = createPromiseAction('LOGIN_REQUEST', 'LOGIN_REQUEST_SUCCEEDED', 'LOGIN_REQUEST_FAILURE')<
  TLoginPayload,
  TLoginSucceededPayload,
  AxiosError<TLoginFailurePayload>
>();

export const setTokenPayload = createAction(
  'SET_TOKEN_PAYLOAD',
  (tokenPayload: TLoginSucceededPayload | null) => tokenPayload,
)<TLoginSucceededPayload | null>();

export const mobileLoginRequest = createPromiseAction(
  'MOBILE_LOGIN_REQUEST',
  'MOBILE_LOGIN_REQUEST_SUCCEEDED',
  'MOBILE_LOGIN_REQUEST_FAILURE',
)<TMobileLoginPayload, TLoginSucceededPayload, AxiosError<TMobileLoginFailurePayload>>();

export const logout = createPromiseAction('LOGOUT_REQUEST', 'LOGOUT_REQUEST_SUCCEEDED', 'LOGOUT_REQUEST_FAILURE')<
  undefined,
  undefined,
  undefined
>();

export const signUp = createPromiseAction('SIGN_UP', 'SIGN_UP_SUCCEEDED', 'SIGN_UP_FAILURE')<
  SignUpPayload,
  undefined,
  AxiosError<SignUpFailurePayload>
>();

export const validateVerificationCode = createPromiseAction(
  'VALIDATE_VERIFICATION_CODE',
  'VALIDATE_VERIFICATION_CODE_SUCCEEDED',
  'VALIDATE_VERIFICATION_CODE_FAILURE',
)<TVerificationCodePayload, undefined, AxiosError<TVerificationCodeFailurePayload>>();

export const resendVerificationCode = createPromiseAction(
  'RESEND_VERIFICATION_CODE',
  'RESEND_VERIFICATION_CODE_SUCCEEDED',
  'RESEND_VERIFICATION_CODE_FAILURE',
)<TVerificationCodePayload, undefined, AxiosError<TVerificationCodeFailurePayload>>();

export const refreshToken = createAsyncAction('REFRESH_TOKEN', 'REFRESH_TOKEN_SUCCEEDED', 'REFRESH_TOKEN_FAILURE')<
  undefined,
  TLoginSucceededPayload,
  undefined
>();

export const remindPassword = createPromiseAction(
  'REMIND_PASSWORD',
  'REMIND_PASSWORD_SUCCEEDED',
  'REMIND_PASSWORD_FAILURE',
)<TRemindPasswordPayload, undefined, AxiosError<TRemindPasswordFailurePayload>>();

export const confirmRemindPassword = createPromiseAction(
  'CONFIRM_REMIND_PASSWORD',
  'CONFIRM_REMIND_PASSWORD_SUCCEEDED',
  'CONFIRM_REMIND_PASSWORD_FAILURE',
)<TConfirmRemindPasswordPayload, undefined, AxiosError<TConfirmRemindPasswordFailurePayload>>();
