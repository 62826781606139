import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'typeface-poppins';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import TagManager from 'react-gtm-module';
import GA from 'ga-4-react';
import { FullPageLoader } from 'components/loaders';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import store, { persistor } from './store';
import * as serviceWorker from './serviceWorker';

if (['production'].includes(process.env.REACT_APP_ENV as string)) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.5,
  });

  const tagManagerArgs = {
    gtmId: String(process.env.REACT_APP_GTM_ID),
  };

  TagManager.initialize(tagManagerArgs);
}

export const ga = ['staging', 'production'].includes(process.env.REACT_APP_ENV as string)
  ? new GA(process.env.REACT_APP_GA_ID as string)
  : null;

(async () => {
  try {
    if (ga && ['staging', 'production'].includes(process.env.REACT_APP_ENV as string) && process.env.REACT_APP_GA_ID) {
      await ga.initialize();
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('[GA] Error: ', err);
  }

  const rootElement = document.getElementById('root');

  const Index = (
    <React.StrictMode>
      <Suspense fallback={<FullPageLoader />}>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={<FullPageLoader />}>
            <ToastContainer />
            <App useSuspense />
          </PersistGate>
        </Provider>
      </Suspense>
    </React.StrictMode>
  );

  if (rootElement?.hasChildNodes()) {
    ReactDOM.hydrate(Index, rootElement);
  } else {
    ReactDOM.render(Index, rootElement);
  }
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
