import { call, put, takeLeading } from 'redux-saga/effects';

import Endpoints from 'endpoints';
import * as actions from 'store/actions/faqs';

import { request } from './api';

function* getFaqs() {
  try {
    const response = yield call(request, {
      method: 'get',
      url: Endpoints.faqsPath(),
    });

    yield put(actions.getFaqs.success(response.data));
  } catch (err) {
    yield put(actions.getFaqs.failure());
  }
}

export const watchFaqs = [takeLeading(actions.getFaqs.request, getFaqs)];
