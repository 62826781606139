import { ActionType } from 'typesafe-actions';
import * as actions from 'store/actions/calendar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions';
import Endpoints from 'endpoints';
import { CalendarFailure, CalendarResponse } from 'store/types';
import GipsyyGDSApi from 'apis/GipsyGDS';

function* getCalendar(action: ActionType<typeof actions.getCalendar.request>) {
  const { idDepartureLocation, idArrivalLocation, departureDate } = action.payload;
  try {
    const response: { data: CalendarResponse } = yield GipsyyGDSApi.get(Endpoints.getCalendar, {
      params: {
        idDepartureLocation,
        idArrivalLocation,
        departureDate,
        quantity: 10,
      },
    });
    yield call(resolvePromiseAction, action, response.data.data);
    yield put(actions.getCalendar.success(response.data.data));
  } catch (error) {
    const { value } = error as CalendarFailure;
    yield put(actions.getCalendar.failure({ value }));
    yield call(rejectPromiseAction, action, error);
  }
}

export const watchCalendar = [takeEvery(actions.getCalendar.request, getCalendar)];
