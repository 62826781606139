import { createPromiseAction } from 'redux-saga-promise-actions';
import {
  NextAvailableTrips,
  NextAvailableTripsPayload,
  NextAvailableTripsFailure,
} from 'store/types/nextavailabletrips';

export const getNextAvailableTrips = createPromiseAction(
  'GET_NEXT_AVAILABLE_TRIPS',
  'GET_NEXT_AVAILABLE_TRIPS_SUCCEEDED',
  'GET_NEXT_AVAILABLE_TRIPS_FAILURE',
)<NextAvailableTripsPayload, Array<NextAvailableTrips>, NextAvailableTripsFailure>();
