import { ActionType, createReducer } from 'typesafe-actions';

import { FiltersState, SortOptions } from 'store/types/filters';
import * as actions from 'store/actions/filters';

const initialState: FiltersState = {
  filter: {
    arrivalTime: {
      afternoon: false,
      morning: false,
      earlyMorning: false,
      night: false,
    },
    departureTime: {
      afternoon: false,
      morning: false,
      earlyMorning: false,
      night: false,
    },
    seatType: {
      comfort: false,
      executive: false,
      bed: false,
      'half-bed': false,
    },
  },
  sort: SortOptions.EARLIEST,
};

export const filtersReducer = createReducer<FiltersState, ActionType<typeof actions>>(initialState)
  .handleAction(actions.toggleSortOption, (state, { payload }) => ({
    ...state,
    sort: payload.name,
  }))
  .handleAction(actions.toggleFilterOption, (state, { payload }) => ({
    ...state,
    filter: {
      ...state.filter,
      [payload.by]: {
        ...initialState.filter[payload.by],
        [payload.name]: !state.filter[payload.by][payload.name],
      },
    },
  }))
  .handleAction(actions.resetFilters, () => ({
    ...initialState,
  }));
