import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from 'store/actions/locations';
import { LocationState } from 'store/types/locations';

const initialState: LocationState = {
  current: null,
  origin: null,
  destination: null,
  origins: [],
  destinations: [],
  locationsFiltered: [],
};

export const locationReducer = createReducer<LocationState, ActionType<typeof actions>>(initialState)
  .handleAction(actions.getCurrentLocation.success, (state, { payload }) => ({
    ...state,
    current: {
      ...state.current,
      latitude: payload.latitude,
      longitude: payload.longitude,
    },
  }))
  .handleAction(actions.saveOriginLocation, (state, { payload }) => ({
    ...state,
    origin: payload.location,
  }))
  .handleAction(actions.saveDestinationLocation, (state, { payload }) => ({
    ...state,
    destination: payload.location,
  }))
  .handleAction(actions.getOriginLocations.success, (state, { payload }) => ({
    ...state,
    origins: payload.locations,
  }))
  .handleAction(actions.getLocations.success, (state, { payload }) => ({
    ...state,
    origin: payload.departureLocation,
    destination: payload.arrivalLocation,
  }))
  .handleAction(actions.getDestinationLocations.success, (state, { payload }) => ({
    ...state,
    destinations: payload.locations,
  }))
  .handleAction(actions.setFilterLocations, (state, { payload }) => ({
    ...state,
    locationsFiltered: payload.locationsFiltered,
  }))
  .handleAction(actions.swapLocations, (state) => ({
    ...state,
    destination: state.origin,
    origin: state.destination,
  }));
