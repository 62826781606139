import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppViews } from 'endpoints';

import styles from './AppCookieConsent.module.css';

const AppCookieConsent: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <CookieConsent
      location="bottom"
      buttonText={t('views:cookies.accept')}
      cookieName="cookieConsentGipsyy"
      containerClasses={styles.container}
      style={{
        background: '#0a3d73',
        opacity: '0.9',
        padding: '0 20px',
        zIndex: '999999999999',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      buttonClasses={styles.cookieButton}
      buttonStyle={{ color: '#000', fontSize: '0.875rem', fontWeight: 500, width: 120, backgroundColor: '#ffb000' }}
      expires={150} // 150 days
    >
      <span className={styles.cookieText}>
        {t('views:cookies.text')}&nbsp;&nbsp;
        <Link target="blank" to={`${AppViews.STATIC_PAGE}/politica-de-privacidade`}>
          {t('views:cookies.privacy')}
        </Link>
      </span>
    </CookieConsent>
  );
};

export default AppCookieConsent;
