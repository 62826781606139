import { ActionType } from 'typesafe-actions';

import * as QrCodeActions from 'store/actions/qrCode';
import { call, debounce, put } from 'redux-saga/effects';
import { QrCodeResponse } from 'store/types/qrCode';
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions';
import Endpoints from 'endpoints';
import GipsyyGDSApi from 'apis/GipsyGDS';

function* getQRCodes(action: ActionType<typeof QrCodeActions.getQRCode.request>) {
  const { paymentID } = action.payload;

  try {
    const response: { data: QrCodeResponse } = yield GipsyyGDSApi.get(Endpoints.getQrCode(paymentID));
    yield put(QrCodeActions.getQRCode.success(response.data));
    yield call(resolvePromiseAction, action, response.data);
  } catch (error) {
    yield put(QrCodeActions.getQRCode.failure());
    yield call(rejectPromiseAction, action, error);
  }
}

export const watchQrCodes = [debounce(1000, QrCodeActions.getQRCode.request, getQRCodes)];
