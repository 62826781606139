import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as PhoneSvg } from 'images/icons/phone.svg';

import { AppViews } from 'endpoints';
import { loginRequest } from 'store/actions/auth';

import { IStore } from 'store/reducers';
import { setLoading } from 'store/actions';

import * as Yup from 'yup';
import cx from 'classnames';
import { trimValues } from 'utils';

import styles from './index.module.css';

interface LoginInitValuesType {
  email: string;
  password: string;
}

const Email: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [otherError, setOtherError] = React.useState('');
  const token = useSelector<IStore, string | null>((state) => state.auth.token);
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('validations:user.email.invalid'))
      .required(t('validations:required', { field: t('views:manageYourBooking.body.haveAccount.emailAddress') })),
    password: Yup.string().required(
      t('validations:required', { field: t('views:manageYourBooking.body.haveAccount.password') }),
    ),
  });

  const { errors, touched, values, handleSubmit, setFieldValue, setFieldTouched } = useFormik<LoginInitValuesType>({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async ({ password, ...payload }) => {
      dispatch(setLoading(true));
      const { email } = trimValues(payload);
      try {
        await dispatch(loginRequest.request({ email, password }));
        dispatch(setLoading(false));
      } catch ({ response }) {
        dispatch(setLoading(false));

        if (response.data?.errorName === 'Errors::Oreons') {
          setOtherError(response.data?.errorMessage);
        } else if (response.data?.errorName === 'Errors::NotConfirmed') {
          history.push(AppViews.CONFIRMATION, { email });
        } else {
          setOtherError(t('validations:somethingWentWrong'));
        }
      }
    },
    validationSchema,
  });

  return (
    <div className="offset-md-2 col-md-8">
      <div className={styles.haveAccountTitle}>
        <h5>{t('views:manageYourBooking.body.haveAccount.title')}</h5>

        <Link
          to={{ pathname: AppViews.SIGN_UP, state: { from: location.pathname } }}
          data-testid="authSignUpAction"
          id="authSignUpAction"
        >
          {t('views:manageYourBooking.body.haveAccount.signUp')}
        </Link>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={cx('mt-1', styles.inputGroup, { [styles.errorBottom]: touched.email && errors.email })}>
          <label htmlFor="login_email" className={styles.inputUnderlined}>
            <input
              id="login_email"
              name="email"
              onChange={({ target: { value, name } }) => setFieldValue(name, value.toLowerCase().trim())}
              onBlur={({ target: { name, value } }) => {
                if (value.length) setFieldTouched(name);
              }}
              value={values.email}
              className={values.email ? styles.presenceField : ''}
            />
            <span className={styles.inputLabel}>{t('views:manageYourBooking.body.haveAccount.emailAddress')}</span>
          </label>
        </div>
        {touched.email && errors.email && <div className={styles.error}>{errors.email}</div>}

        <div className={cx('mt-4', styles.inputGroup, { [styles.errorBottom]: touched.password && errors.password })}>
          <label htmlFor="password" className={styles.inputUnderlined}>
            <input
              id="password"
              type="password"
              name="password"
              onChange={({ target: { value, name } }) => setFieldValue(name, value)}
              onBlur={({ target: { name, value } }) => {
                if (value.length) setFieldTouched(name);
              }}
              value={values.password}
              className={values.password ? styles.presenceField : ''}
            />
            <span className={styles.inputLabel}>{t('views:manageYourBooking.body.haveAccount.password')}</span>
          </label>
        </div>
        {touched.password && errors.password && <div className={styles.error}>{errors.password}</div>}

        {otherError.length > 0 && <div className={cx('mt-2', styles.error)}>{otherError}</div>}

        <div className="mt-4 d-flow-root">
          <div className="float-left">
            <p className={`mt-3 ${styles.forgotPasswordBtn}`}>
              <Link to={{ pathname: AppViews.FORGOT_PASSWORD, state: { from: AppViews.MANAGE_YOUR_BOOKING } }}>
                {t('views:manageYourBooking.body.haveAccount.forgotPassword?')}
              </Link>
            </p>
          </div>
          <div className={`float-right ${styles.signInBtn}`}>
            {!token && (
              <button type="submit" data-testid="authSignInSubmitAction" id="authSignInSubmitAction">
                {t('views:manageYourBooking.body.haveAccount.signIn')}
              </button>
            )}
          </div>
        </div>
      </form>

      <div className="text-center mt-3">
        <p>{t('views:manageYourBooking.body.haveAccount.or')}</p>
      </div>

      <div className={`text-center ${styles.signInMobileBtn}`}>
        <span className="pr-3">
          <PhoneSvg />
        </span>
        <p className="d-inline">
          <Link to={AppViews.SIGN_IN_MOBILE}>{t('views:manageYourBooking.body.haveAccount.signInMobile')}</Link>
        </p>
      </div>
    </div>
  );
};

export default Email;
