import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from 'store/actions';
import jwt from 'jwt-decode';

const parseJwt: any = (token: string) => {
  try {
    const user = jwt(token);
    return user;
  } catch (e) {
    return null;
  }
};

const ExpirationVerify = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  history?.listen(() => {
    const user = localStorage.getItem('authData');
    const response = user ? JSON.parse(user) : '';
    const { token } = response;
    if (user) {
      const decodedJwt = parseJwt(token);
      if (decodedJwt?.exp * 1000 < Date.now()) {
        dispatch(logout.request());
      }
    }
  });
  return <div />;
};

export default withRouter(ExpirationVerify);
