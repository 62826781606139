import { ActionType } from 'typesafe-actions';
import i18n from 'i18n';
import * as bannerActions from 'store/actions/banners';
import { call, put, takeEvery } from 'redux-saga/effects';
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions';
import Endpoints from 'endpoints';
import { Banner } from 'store/types';
import GipsyyGDSApi from 'apis/GipsyGDS';

function* getBanners(action: ActionType<typeof bannerActions.getBanners.request>) {
  const { language } = i18n;
  try {
    const { bannerType } = action.payload;
    const response: { data: { data: Array<Banner> } } = yield GipsyyGDSApi.get(Endpoints.getBanners);
    const { data } = response.data;
    const banners = data.filter((banner) => banner.category === `${bannerType}-${language}`);
    yield call(resolvePromiseAction, action, banners);
    yield put(bannerActions.getBanners.success(banners));
  } catch (error) {
    yield put(bannerActions.getBanners.failure());
    yield call(rejectPromiseAction, action, error);
  }
}

export const watchBanner = [takeEvery(bannerActions.getBanners.request, getBanners)];
