import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import cx from 'classnames';

import { ReactComponent as CloseSvg } from 'images/icons/iconfinder_x.svg';

import { Tab } from 'components/auth/ManageYourBooking';
import { AppViews } from 'endpoints';
import { User } from 'models/User';
import { IStore } from 'store/reducers';

import { ReactComponent as Ticket } from 'images/cancelRebuyIcon.svg';
import { ReactComponent as AboutUs } from 'images/menuIcons/aboutus.svg';
import { ReactComponent as Alugueres } from 'images/menuIcons/alugueres.svg';
import { ReactComponent as Animals } from 'images/menuIcons/animals.svg';
import { ReactComponent as Blog } from 'images/menuIcons/blog.svg';
import { ReactComponent as Destination } from 'images/menuIcons/departure.svg';
import { ReactComponent as Chat } from 'images/menuIcons/help.svg';
import { ReactComponent as Luggage } from 'images/menuIcons/luggage.svg';
import { ReactComponent as Station } from 'images/menuIcons/station.svg';
import { ReactComponent as Stops } from 'images/menuIcons/stops.svg';
import { ReactComponent as Trips } from 'images/menuIcons/trips.svg';

import styles from './Sidebar.module.css';

interface SidebarLinkItemProps {
  to: { pathname: string; state?: { tab: Tab } };
  id: string;
  Icon?: React.FC;
  label: string;
  target?: string;
}

interface Props {
  displaySidebar: boolean;
  handleHideSidebar: () => void;
}

const Sidebar: React.FC<Props> = ({ displaySidebar, handleHideSidebar }) => {
  const { t } = useTranslation();
  const token = useSelector<IStore, string | null>((state) => state.auth.token);
  const currentUser = useSelector<IStore, User | null>((state: IStore) => state.users.currentUser);

  const ref = React.useRef<HTMLDivElement>(null);

  const handleOutsideClick = React.useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current?.contains(e.target as Node)) return;

      handleHideSidebar();
    },
    [handleHideSidebar],
  );

  React.useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    document.addEventListener('touchstart', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const isCurrentPage = (pathname: string) => window.location.pathname === pathname;

  const SidebarLinkItem: React.FC<SidebarLinkItemProps> = ({ to, id, Icon, label, target }) => (
    <Link
      to={to}
      id={id}
      data-testid={id}
      className={cx({ [styles.currentLink]: isCurrentPage(to.pathname) })}
      onClick={handleHideSidebar}
      target={target}
    >
      {Icon && <Icon />}
      <span>{label}</span>
    </Link>
  );

  return (
    <>
      <div className={cx(styles.background, { [styles.overlay]: displaySidebar })} />

      <div ref={ref} className={`${styles.sidebar} ${displaySidebar ? styles.show : ''}`}>
        <div className={`${styles.sidebarContent} ${displaySidebar ? styles.show : styles.hide}`}>
          <div className={styles.topSideBar}>
            <CloseSvg onClick={handleHideSidebar} className={styles.close} />
            {token ? (
              <>
                {currentUser?.firstName && (
                  <p>
                    {t(`views:sidebar.hello`)} {currentUser?.firstName} {currentUser?.lastName}
                  </p>
                )}
                <SidebarLinkItem
                  to={{ pathname: AppViews.USER_BOOKINGS }}
                  id="menuMyTickets"
                  label={t('views:sidebar.myTickets')}
                />
              </>
            ) : (
              <SidebarLinkItem
                to={{ pathname: AppViews.MANAGE_YOUR_BOOKING, state: { tab: Tab.haveAnAccount } }}
                id="authSignInAction"
                label={t('views:sidebar.signIn')}
              />
            )}
          </div>

          <div className={styles.staticPages}>
            <SidebarLinkItem
              to={{ pathname: AppViews.HOME }}
              id="menuBuyTicket"
              Icon={Ticket}
              label={t('views:sidebar.buy')}
            />

            <SidebarLinkItem
              to={
                currentUser?.firstName
                  ? { pathname: AppViews.USER_BOOKINGS, state: { tab: Tab.haveBookingId } }
                  : { pathname: AppViews.MANAGE_YOUR_BOOKING }
              }
              id="menuMyTrips"
              Icon={Trips}
              label={t('views:sidebar.booking')}
            />

            <SidebarLinkItem
              to={{ pathname: AppViews.HELP }}
              id="menuHelp"
              Icon={Chat}
              label={t('views:header.help')}
            />

            <SidebarLinkItem
              to={{ pathname: AppViews.ALUGUERES }}
              id="menuFreight"
              Icon={Alugueres}
              label={t('views:sidebar.rental')}
            />

            <SidebarLinkItem
              to={{ pathname: AppViews.STOPS_INFO }}
              id="menuWhereToBoard"
              Icon={Station}
              label={t('views:header.stopsInformation')}
            />

            <SidebarLinkItem
              to={{ pathname: AppViews.ROADMAP }}
              id="menuRoadmap"
              Icon={Stops}
              label={t('views:sidebar.roadmap')}
            />

            <SidebarLinkItem
              to={{ pathname: AppViews.DESTINATIONS }}
              id="menuOurDestinations"
              Icon={Destination}
              label={t('views:sidebar.destinations')}
              target="_blank"
            />

            <SidebarLinkItem
              to={{ pathname: AppViews.BAGGAGE }}
              id="menuBag"
              Icon={Luggage}
              label={t('views:sidebar.baggage')}
            />

            <SidebarLinkItem
              to={{ pathname: AppViews.ANIMAL_TRANSPORT }}
              id="menuTransportAnimals"
              Icon={Animals}
              label={t('views:sidebar.animal')}
            />

            <SidebarLinkItem
              to={{ pathname: AppViews.BLOG }}
              id="menuBlog"
              Icon={Blog}
              label={t('views:sidebar.blog')}
              target="_blank"
            />

            <SidebarLinkItem
              to={{ pathname: AppViews.ABOUT }}
              id="menuAbout"
              Icon={AboutUs}
              label={t('views:sidebar.about')}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
