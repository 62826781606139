export type FiltersState = {
  sort: SortOptions;
  filter: FilterOptions;
};

export enum SortOptions {
  EARLIEST = 'earliest',
  CHEAPEST = 'cheapest',
  SHORTEST = 'shortest',
}

export type SeatType = {
  comfort: boolean;
  executive: boolean;
  'half-bed': boolean;
  bed: boolean;
};

export type TimeType = {
  earlyMorning: boolean; // 00:00 - 06:00
  morning: boolean; // 06:00 - 12:00
  afternoon: boolean; // 12:00 - 18:00
  night: boolean; // 18:00 - 00:00
};

export type FilterOptions = {
  seatType: SeatType & Partial<TimeType>;
  departureTime: TimeType & Partial<SeatType>;
  arrivalTime: TimeType & Partial<SeatType>;
};
