export interface Banner {
  category: string;
  idCategory: number;
  imageUrlDesktop: string;
  imageUrlMobile: string;
  sequence: number;
  associationLink: string;
  description: string;
  buttonText: string;
}

export enum Type {
  HERO_BANNER = 'Hero',
  GENERIC_BANNER = 'Generic',
  FOOTER_BANNER = 'Footer',
}

export interface BannerType {
  bannerType: Type;
}

export interface BannerProps {
  banner: Banner;
}
