import { createAction } from 'typesafe-actions';
import { FilterOptions, SeatType, SortOptions, TimeType } from 'store/types/filters';

export const toggleFilterOption = createAction(
  'TOGGLE_FILTER_OPTION',
  (by: keyof FilterOptions, name: keyof SeatType | keyof TimeType) => ({
    by,
    name,
  }),
)<{ by: keyof FilterOptions; name: keyof SeatType | keyof TimeType }>();

export const toggleSortOption = createAction('TOGGLE_SORT_OPTION', (name: SortOptions) => ({ name }))<{
  name: SortOptions;
}>();

export const resetFilters = createAction('RESET_FILTERS')<undefined>();
