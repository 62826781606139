import { ActionType } from 'typesafe-actions';
import { call, put, takeLeading } from 'redux-saga/effects';
import { resolvePromiseAction, rejectPromiseAction } from 'redux-saga-promise-actions';

import Endpoints from 'endpoints';
import * as actions from 'store/actions/static_pages';

import { request } from './api';

function* getStaticPages() {
  try {
    const response = yield call(request, {
      method: 'get',
      url: Endpoints.staticPagesPath(),
    });

    yield put(actions.getStaticPages.success(response.data));
  } catch (err) {
    yield put(actions.getStaticPages.failure());
  }
}

function* searchHelpPage(action: ActionType<typeof actions.searchHelpPage.request>) {
  try {
    const { content, help } = action.payload;

    const response = yield call(request, {
      method: 'get',
      url: Endpoints.staticPagesPath(),
      params: {
        help,
        content,
      },
    });

    yield call(resolvePromiseAction, action, response.data);
    yield put(actions.searchHelpPage.success(response.data));
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.searchHelpPage.failure());
  }
}

export const watchStaticPages = [
  takeLeading(actions.getStaticPages.request, getStaticPages),
  takeLeading(actions.searchHelpPage.request, searchHelpPage),
];
