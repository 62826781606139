import { ActionType, createReducer } from 'typesafe-actions';

import { SearchState } from 'store/types/search';

import * as actions from 'store/actions';

const initialState: SearchState = {
  searchForm: {
    departDate: null,
    returnDate: null,
    passengers: {
      adult: 1,
      baby: 0,
      child: 0,
    },
    isAutomatic: false,
    origin: null,
    destination: null,
    isSearch: false,
  },
};

export const searchReducer = createReducer<SearchState, ActionType<typeof actions>>(initialState)
  .handleAction(actions.addToSearchForm, (state, { payload }) => {
    return {
      ...state,
      searchForm: {
        ...state.searchForm,
        [payload.newField.field]: payload.newField.value,
      },
    };
  })
  .handleAction(actions.closeTrip.success, (state, { payload }) => ({
    ...state,
    searchForm: {
      ...state.searchForm,
      // departDate: payload.return ? state.searchForm.departDate : state.searchForm.returnDate,
      // returnDate: null,
    },
  }))
  .handleAction(actions.setPassengers, (state, { payload }) => ({
    ...state,
    searchForm: {
      ...state.searchForm,
      passengers: payload.passengers,
    },
  }));
