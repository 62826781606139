import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from 'store/actions/routes';

import { RouteResponse } from 'store/types/routes';

const initialState: RouteResponse = {
  destinationId: [],
  startDates: [],
  destinationIdsWithNames: []
};

export const RoutesReducer = createReducer<RouteResponse, ActionType<typeof actions>>(initialState).handleAction(
  actions.getRoute.success,
  (state, { payload }) => ({
    ...state,
    destinationId: [...payload.destinationId],
    startDates: [...payload.startDates],
    destinationIdsWithNames: [...payload.destinationIdsWithNames]
  }),
);
