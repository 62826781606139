import React from 'react';

import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { IStore } from 'store/reducers';
import { AuthState } from 'store/types/auth';
import { AppViews } from 'endpoints';

const GuestGuard: React.FC = ({ children }) => {
  const { token } = useSelector<IStore, AuthState>((state) => state.auth);

  if (token) {
    return <Redirect to={AppViews.HOME} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
