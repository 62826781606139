import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from 'store/actions/paymentdata';

import { PaymentDataResponse } from 'store/types/paymentdata';

const initialState: Array<PaymentDataResponse> = [];

export const PaymentDataReducer = createReducer<Array<PaymentDataResponse>, ActionType<typeof actions>>(
  initialState,
).handleAction(actions.getPaymentData.success, (state, { payload }) => ({
  ...state,
  ...payload,
}));
