import { ActionType } from 'typesafe-actions';
import { call, put, takeEvery, debounce, takeLeading } from 'redux-saga/effects';
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions';

import GipsyyGDSApi from 'apis/GipsyGDS';
import Endpoints from 'endpoints';
import Location from 'models/Location';

import * as actions from 'store/actions/locations';
import { AxiosResponse } from 'axios';

const getUserLocation = (): Promise<Position> =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (location) => resolve(location),
      (error) => reject(error),
    );
  });

function* getCurrentLocation() {
  try {
    const location: Position = yield call(getUserLocation);
    const { latitude, longitude } = location.coords;

    yield put(actions.getCurrentLocation.success({ longitude, latitude }));
  } catch (err) {
    yield put(actions.getCurrentLocation.failure());
  }
}

function* fetchLocations(action: ActionType<typeof actions.getLocations.request>) {
  try {
    const response: AxiosResponse<Location[]> = yield GipsyyGDSApi.get(Endpoints.locationsPath(), {
      params: {
        externalIds: Object.values(action.payload),
      },
    });

    const departureLocation = response.data.find(
      ({ externalId }) => externalId.toString() === action.payload.departureLocation,
    );

    const arrivalLocation = response.data.find(
      ({ externalId }) => externalId.toString() === action.payload.arrivalLocation,
    );

    if (!departureLocation) return;

    yield call(resolvePromiseAction, action, { arrivalLocation, departureLocation });
    yield put(actions.getLocations.success({ arrivalLocation, departureLocation }));
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.getLocations.failure());
  }
}

function* fetchOriginLocations(action: ActionType<typeof actions.getOriginLocations.request>) {
  try {
    const response = yield GipsyyGDSApi.get(Endpoints.locationsPath(), {
      params: action.payload,
    });

    yield put(actions.getOriginLocations.success({ locations: response.data }));
  } catch (err) {
    yield put(actions.getOriginLocations.failure());
  }
}

function* fetchDestinationLocations(action: ActionType<typeof actions.getDestinationLocations.request>) {
  try {
    const response = yield GipsyyGDSApi.get(Endpoints.locationsPath(), {
      params: action.payload,
    });

    yield put(actions.getDestinationLocations.success({ locations: response.data }));
  } catch (err) {
    yield put(actions.getDestinationLocations.failure());
  }
}

function* fetchTopOriginLocations(action: ActionType<typeof actions.getTopOriginLocations.request>) {
  try {
    const { perPage } = action.payload;

    const response = yield GipsyyGDSApi.get(Endpoints.locationsPath(), {
      params: {
        topOrigin: true,
        perPage,
      },
    });

    yield call(resolvePromiseAction, action, { locations: response.data });
    yield put(actions.getTopOriginLocations.success({ locations: response.data }));
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.getTopOriginLocations.failure());
  }
}

function* fetchTopDestinationLocations(action: ActionType<typeof actions.getTopDestinationLocations.request>) {
  try {
    const { perPage } = action.payload;

    const response = yield GipsyyGDSApi.get(Endpoints.locationsPath(), {
      params: {
        topDestination: true,
        perPage,
      },
    });

    yield call(resolvePromiseAction, action, { locations: response.data });
    yield put(actions.getTopDestinationLocations.success({ locations: response.data }));
  } catch (err) {
    yield call(rejectPromiseAction, action, err);
    yield put(actions.getTopDestinationLocations.failure());
  }
}

export const watchLocations = [
  takeEvery(actions.getCurrentLocation.request, getCurrentLocation),
  debounce(500, actions.getOriginLocations.request, fetchOriginLocations),
  debounce(500, actions.getDestinationLocations.request, fetchDestinationLocations),
  takeLeading(actions.getTopOriginLocations.request, fetchTopOriginLocations),
  takeLeading(actions.getLocations.request, fetchLocations),
  takeEvery(actions.getTopDestinationLocations.request, fetchTopDestinationLocations),
];
