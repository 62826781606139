import { ActionType } from 'typesafe-actions';

import * as discountCodeActions from 'store/actions/discountCode';
import { call, put, takeLeading } from 'redux-saga/effects';
import { DiscountCodeResponse } from 'store/types/discountCode';
import { rejectPromiseAction, resolvePromiseAction } from 'redux-saga-promise-actions';
import Endpoints from 'endpoints';
import GipsyyGDSApi from 'apis/GipsyGDS';
import { AxiosError } from 'axios';

function* validateDiscountCode(action: ActionType<typeof discountCodeActions.validateDiscountCode.request>) {
  const { promoCode, paymentId } = action.payload;

  try {
    const response: { data: DiscountCodeResponse } = yield GipsyyGDSApi.get(Endpoints.validateDiscount(), {
      params: {
        promoCode,
        paymentId,
      },
    });
    yield put(discountCodeActions.validateDiscountCode.success({ price: response.data.price }));
    yield call(resolvePromiseAction, action, { price: response.data.price });
  } catch (error) {
    const { response } = error as AxiosError;
    yield put(discountCodeActions.validateDiscountCode.failure({ title: response?.data.title ?? '' }));
    yield call(rejectPromiseAction, action, { title: response?.data.title ?? '' });
  }
}

function* applyDiscountCode(action: ActionType<typeof discountCodeActions.applyDiscountCode.request>) {
  const { promoCode, paymentId } = action.payload;

  try {
    yield GipsyyGDSApi.patch(Endpoints.applyDiscountCode(), {
      paymentId,
      promoCode,
    });
    yield put(discountCodeActions.applyDiscountCode.success());
    yield call(resolvePromiseAction, action);
  } catch (error) {
    yield put(discountCodeActions.applyDiscountCode.failure());
    yield call(resolvePromiseAction, action);
  }
}

export const watchDiscountCode = [
  takeLeading(discountCodeActions.validateDiscountCode.request, validateDiscountCode),
  takeLeading(discountCodeActions.applyDiscountCode.request, applyDiscountCode),
];
